import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import general from '../json/general.json'
import DownloadBrochureModal from './DownloadBrochureModal';

function Footer(props) {
    const location = useLocation();

    const submitForm=()=>{
        var form2 = document.getElementById('form2');
        form2.action = 'https://profiles.dunsregistered.com/TPIN-BAS-004.aspx';
        window.open('', 'formresult','toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=1020,height=700,Left=0,top=0,titlebar=yes');
        form2.submit();
    }
    
    return (
        <div className="footer-main"><a id="scrollTopBtn" style={{display:'none'}} className="scroll-btn show"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg></a>
            <div className="footer-top">
                <div className="container container-big">
                    <div className="footer-top-inn">
                        <div className="footer-top-left">
                            <h5 className="footer-tagline" dangerouslySetInnerHTML={{__html:general.vFooter.vFooterHeading}}></h5>
                            {/* <h5 className="footer-tagline">{general.vFooter.vFooterHeading}</h5> */}
                            <ul className="f-btn-list">
                                <li><Link to="/enquiry" className="btn btn-blue">{general.vFooter.vFEnquiryBtnLabel}</Link></li>
                                <li><a className="btn-line btn-blue btn-icon" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalDownloadBrochure"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 21.5H6a2.982 2.982 0 0 1-2.121-.879A2.978 2.978 0 0 1 3 18.5v-2a1 1 0 1 1 2 0v2c0 .264.107.521.293.707.186.186.444.293.707.293h12a1.002 1.002 0 0 0 1-1v-2a1 1 0 1 1 2 0v2c0 .801-.313 1.555-.879 2.121s-1.32.879-2.121.879zm-6-5h-.003a.988.988 0 0 1-.704-.293l-5-5a.999.999 0 1 1 1.414-1.414L11 13.086V3.5a1 1 0 0 1 2 0v9.586l3.293-3.293a.999.999 0 1 1 1.414 1.414l-5 5a.988.988 0 0 1-.704.293H12z"></path></svg>{general.vFooter.vBrochureLabel}</a></li>
                            </ul>
                            <ul className="f-address">
                                <div className="left-part">
                                    <li>
                                        <div className="name">{general.vFooter.vFooterContactAddressLabel}</div>
                                        <div dangerouslySetInnerHTML={{__html:general.vFooter.vFooterContactAddress}}></div>
                                    </li>
                                </div>
                                <div className="left-part right-part">
                                    <li>
                                        <div className="name">Contact No.</div>
                                        {general.vFooter.vFooterContactNo}
                                    </li>
                                    <li>
                                        <div className="name">Email</div>
                                        <a href={`mailto:${general.vFooter.vFooterContactEmail}`}>{general.vFooter.vFooterContactEmail}</a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div className="footer-top-right">
                            <div className="f-right-inn">
                                <div className="f-list f-menu-main">
                                    <h3 className="f-tit">Menu</h3>
                                    <ul className="f-menu">
                                        {
                                            Object.keys(general.vHeader.vMenuList).map((curEle,index)=>{
                                                const pageName=general.vHeader.vMenuList[curEle]['vMenu'];
                                                const vMenuLink=general.vHeader.vMenuList[curEle]['vMenuLink'];
                                                return  <li className={location.pathname == vMenuLink || (location.pathname == '' && pageName=="Home") || (location.pathname.includes("/news/") && vMenuLink=="/news-events") || (location.pathname.includes("/product/") && vMenuLink=="/products") || (location.pathname.includes("/sub-product/") && vMenuLink=="/products") ? "active" : ""}>
                                                            <Link to={vMenuLink}>{pageName}</Link>
                                                        </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="f-list f-certificate">
                                    <h3 className="f-tit">{general.vFooter.vCertificateTitle}</h3>
                                    <div className="duns-certificate">
                                    <form id="form2" method="post" action="" target="formresult">
                                            <input className="api_key_cart" name="iSealKey" type="text" id="iSealKey" style={{display:'none'}} value={props.dunsAccess}/>
                                            <a id="ATpSeal" onClick={submitForm} target="_parent"><img loading="lazy" src={general.vFooter.vCertificateLogo} alt="D&amp;B D-U-N-S Registered" title="D&amp;B D-U-N-S Registered"/></a>
                                        </form>
                                    </div>

                                    
                                </div>
                                <div className="f-list f-social-main">
                                    <h3 className="f-tit">Social</h3>
                                    <ul className="f-social">
                                        {
                                          Object.keys(general.vFooter.vSocialMediaList).map((curEle,index)=>{
                                            const vSocialMediaLogo=general.vFooter.vSocialMediaList[curEle]['vSocialMediaLogo']
                                            const vSocialMediaLink=general.vFooter.vSocialMediaList[curEle]['vSocialMediaLink']
                                            return <li key={index}>
                                                        <Link target="_blank" rel="noopener noreferrer" to={vSocialMediaLink}> 
                                                        <div dangerouslySetInnerHTML={{__html: vSocialMediaLogo}}></div>
                                                        </Link>
                                                    </li>
                                          })  
                                        }
                                    </ul>
                                    <div className="f-contact-btn">
                                        <Link className="btn btn-blue btn-icon-right" to={general.vFooter.vContactBtnLink}>
                                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="20" height="20" viewBox="0 -2 20 20"><path fill="currentColor" d="M12 16a.999.999 0 0 1-.707-1.707L16.586 9H1a1 1 0 1 1 0-2h15.586l-5.293-5.293A.999.999 0 1 1 12.707.293l7 7a.993.993 0 0 1 .293.704v.006a.988.988 0 0 1-.293.704l-7 7A.997.997 0 0 1 12 16z"></path></svg>{general.vFooter.vContactBtnLabel}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="f-small-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shiv-om-brass-logo-f.png`} title="Shiv Om Brass" alt="Shiv Om Brass Industries" /></div>
                    {/* <div className="f-big-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shiv-om-brass-logo-f.png`} title="Shiv Om Brass" alt="Shiv Om Brass Industries" /></div> */}
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container container-big">
                    <div className="bottom-inn">
                        <ul className="bottom-left">
                            <li className="copy">&copy; {general.vFooter.vCopyrightText} {new Date().getFullYear()}</li>
                        </ul>
                        <ul className="bottom-left f-menu-copy">
                            {
                                Object.keys(general.vFooter.vFooterLinkList).map((curEle,index)=>{
                                    const vLabel=general.vFooter.vFooterLinkList[curEle]['vLabel']
                                    const vLink=general.vFooter.vFooterLinkList[curEle]['vLink']
                                    return <li key={index} className={`${location.pathname==vLink?'active':''}`}><Link to={vLink}>{vLabel}</Link></li>
                                })
                            }
                            {/* <li className="design-by"><a title="Enliven Digital Content" target="_blank" href="http://enlivendc.com/">enliven</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>

            <DownloadBrochureModal/>
        </div>

        
    )
}

export default Footer