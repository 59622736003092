import React, { useEffect } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import ContactData from './json/contact.json';

function Contact() {
    useEffect(()=>{
        scrollBottomToTop();
    },[])

    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white contact-section">
                    <img loading="lazy" className="contact-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries"/>
                        <div className="container container-big">
                            <div className="contact-sec">
                                <div className="contact-list left">
                                    <h4 className="tit"><span className="sub-tit">{ContactData?.section1?.vTitle}</span>{ContactData?.section1?.vHeading}</h4>
                                </div>
                                <div className="contact-list right">
                                    <div className="desc">{ContactData?.section1?.vText}</div>
                                    <ul className="contact-list">
                                        <li className="contact-sub">
                                            <div className="list address-icon">
                                                <div className="name"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/icon/map.svg`} alt="Address" title="Address" />Address</div>
                                            </div>
                                            <div className="list address">
                                                <div className="company-name">{ContactData?.section1?.vCompany}</div>
                                                <div dangerouslySetInnerHTML={{__html: ContactData?.section1?.vAddress}}></div>
                                            </div>
                                            <div className="list map">
                                                <a className="btn" target="_blank" href={ContactData?.section1?.vDirectionLink}>{ContactData?.section1?.vDirectionLabel}</a>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3688.2534353891992!2d70.050944!3d22.419485!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39571530f2b2915d%3A0xb893d2032b568d4e!2sShiv-Om%20Brass%20Industries!5e0!3m2!1sen!2sin!4v1694760099121!5m2!1sen!2sin" width="100%" height="240" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </li>
                                        {
                                            ContactData?.section1?.vPhone ?
                                            <li className="contact-sub">
                                                <div className="list address-icon">
                                                    <div className="name"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/icon/phone.svg`} alt="Phone" title="Phone" />Phone</div>
                                                </div>
                                                <div className="list address">
                                                    {ContactData?.section1?.vPhone}
                                                </div>
                                            </li>
                                            : ''
                                        }
                                        {
                                            ContactData?.section1?.vEmail ?
                                            <li className="contact-sub">
                                                <div className="list address-icon">
                                                    <div className="name"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/icon/email.svg`} alt="Email" title="Email" />Email</div>
                                                </div>
                                                <div className="list address">
                                                    <a className="link" href={`mailto:${ContactData?.section1?.vEmail}`}>{ContactData?.section1?.vEmail}</a>
                                                    
                                                </div>
                                            </li>
                                            : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default Contact