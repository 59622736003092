import React, { useEffect } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import { useLocation, useNavigate } from 'react-router-dom';
import blogData from './json/blog.json'
import moment from 'moment'


function BlogDetail() {
    useEffect(()=>{
        scrollBottomToTop();
    },[])

    const location = useLocation();
    const navigate = useNavigate();

    // const blogDataNew=blogData?.blogList.length>0?blogData?.blogList[location?.state?.id]:{};

    const filterBlogData=blogData?.blogList?.filter((curEle,index)=>{
        const b = curEle.vTitle.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        return "/news/"+b==location.pathname
    })
    if(filterBlogData.length==0){
        window.location.href = "/";
    }

    const blogDataNew=filterBlogData[0];

    // if(!location?.state?.id){
    //     navigate(`/news-events`);
    // }
    
    let blogTags = blogDataNew?.vTags;
    const blogTagArr = blogTags?.split(",");


    function convertDateFormat(inputDate) {
        const parsedDate = moment(inputDate, 'DD/MM/YYYY');
        const formattedDate = parsedDate.format('DD, MMMM YYYY');
        return formattedDate;
    }

    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white blog-header-section pdb-0">
                    <img loading="lazy" className="header-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container">
                        <div className="blog-header-inn">
                            <div className="blog-header-list left">
                                <div className="post-cat">In <span>{blogDataNew?.vCategory?.join(', ')}</span><span className="date">{convertDateFormat(''+blogDataNew?.dPublishDate)}</span></div>
                                <h2 className="title">{blogDataNew?.vTitle}</h2>
                            </div>
                            <div className="blog-header-list right">
                                <div className="post-image">
                                    <img loading="lazy" src={blogDataNew?.vBlogImage} title={blogDataNew?.vImageTitleText} alt={blogDataNew?.vImageAltText} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white content-section blog-detail-page-section">
                    <div className="container">
                        {/* <p className="lead">{blogDataNew?.vBlogDesc}</p> */}
                        {/* {
                            blogDataNew?.vblogContent!=""?
                            <div dangerouslySetInnerHTML={{__html:blogDataNew?.vblogContent}}></div>
                            :""
                        } */}
                        {
                            Object.keys(blogDataNew?.vblogContentData)?.map((curEle,index)=>{
                                const contentType=blogDataNew?.vblogContentData[curEle]['content_type'];
                                const contentData=blogDataNew?.vblogContentData[curEle]['vblogContent'];
                                return contentType=='image'?<p key={index}><img loading="lazy" src={contentData} title={blogDataNew?.vImageTitleText} alt={blogDataNew?.vImageAltText} /></p>:<div key={index} dangerouslySetInnerHTML={{__html:contentData}}></div>
                            })
                        }
                       
                       {
                        blogTagArr.length>0?
                        <div className="has-tag">
                            {
                                blogTagArr.map((curEle,index)=>{
                                    return <a href="javascript:;" key={index}>{curEle}</a>
                                })
                            }
                        </div>
                        :""
                       }
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default BlogDetail