import React, { useEffect } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import genera from './json/general.json';
import blogData from './json/blog.json';
import { useNavigate } from 'react-router-dom';

function Blog() {
    const navigate = useNavigate();

    useEffect(()=>{
        scrollBottomToTop();
    },[])

    const blogDetailPage = (name) =>{
        const b = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        navigate(`/news/${b}`);
    };

    const filterBlog=blogData?.blogList.sort((a, b) => {
        // Convert date strings to Date objects for comparison
        const dateA = new Date(a.dPublishDate.split('/').reverse().join('/'));
        const dateB = new Date(b.dPublishDate.split('/').reverse().join('/'));
      
        // Compare the dates
        return dateA - dateB;
    });
    
    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white comman-header-section pdb-0">
                    <img loading="lazy" className="header-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container">
                        <div className="section-title2">
                            <h2 className="title"><span className="sub-title">{genera?.aBlogArr?.vBlogPageHeading}</span>{genera?.aBlogArr?.vBlogPageSubHeading}</h2>
                            <div className="desc">{genera?.aBlogArr?.vBlogPageParagraph}</div>
                        </div>
                    </div>
                </div>
                <div className="section-white blog-page-section">
                    <div className="container">
                        <ul className="blog-list-sec">
                            {
                                filterBlog?.reverse().map((curEle,index)=>{
                                    const blogDataSub=curEle;
                                    const vBlogDesc=blogDataSub?.vBlogDesc?.substr(0,118) + '...';
                                    return <li className="blog-list" key={index}>
                                                <div className="blog-list-inn">
                                                    <div className="post-image">
                                                        <a href='javascript:void(0)' onClick={()=>{blogDetailPage(blogDataSub?.vTitle)}}><img loading="lazy" src={blogDataSub?.vBlogImage} title={blogDataSub?.vImageTitleText} alt={blogDataSub?.vImageAltText} /></a>
                                                    </div>
                                                    <div className="post-cap">
                                                        <div className="post-cat">In {blogDataSub?.vCategory?.join(', ')}</div>
                                                        <h3 className="post-title"><a href='javascript:void(0)' onClick={()=>{blogDetailPage(blogDataSub?.vTitle)}}>{blogDataSub?.vTitle}</a></h3>
                                                        <div className="post-desc">{vBlogDesc}</div>
                                                        <a className="btn-outline hvr-sweep-to-top" href='javascript:void(0)' onClick={()=>{blogDetailPage(blogDataSub?.vTitle)}}>Read More</a>
                                                    </div>
                                                </div>
                                            </li>
                                })
                            }
                            
                        </ul>
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default Blog