import { Link, useLocation } from "react-router-dom"
import general from '../json/general.json'
import homeData from '../json/home.json'; 
import aboutData from '../json/about.json';
import qualityData from '../json/quality.json';
import careerData from '../json/career.json';
import ContactData from "../json/contact.json";
import TermsCondData from "../json/termscondition.json";
import PrivacyPolicy from "../json/privacypolicy.json";
import CovidData from "../json/covid.json";
import { useEffect, useState } from "react";

function Header() {
    const location = useLocation();

    // console.log("location",location)

    const PageSeoFun=(title,dec,keyword)=>{
        document.querySelector('meta[name="description"]').setAttribute("content", dec);
        document.querySelector('meta[name="keywords"]').setAttribute("content", keyword);
        document.title = title;
    }

    if(location.pathname=="" || location.pathname=="/"){
        PageSeoFun(homeData?.vSEOTitle,homeData?.vMetaDesc,homeData?.vKeywords)
    }else if(location.pathname=="/company"){
        PageSeoFun(aboutData?.vSEOTitle,aboutData?.vMetaDesc,aboutData?.vKeywords)
    }else if(location.pathname=="/quality"){
        PageSeoFun(qualityData?.vSEOTitle,qualityData?.vMetaDesc,qualityData?.vKeywords)
    }else if(location.pathname=="/products" || location.pathname=="/products-detail"){
        PageSeoFun(general?.aProduct?.vProductSeoTitle,general?.aProduct?.vProductMetaDescription,general?.aProduct?.vProductKeywords)
    }else if(location.pathname=="/news-events" || location.pathname=="/news-events-detail"){
        PageSeoFun(general?.aBlogArr?.vBlogSeoTitle,general?.aBlogArr?.vProductMetaDescription,general?.aBlogArr?.vProductKeywords)
    }else if(location.pathname=="/career"){
        PageSeoFun(careerData?.vSEOTitle,careerData?.vMetaDesc,careerData?.vKeywords)
    }else if(location.pathname=="/contact-us"){
        PageSeoFun(ContactData?.vSEOTitle,ContactData?.vMetaDesc,ContactData?.vKeywords)
    }else if(location.pathname=="/terms-and-conditions"){
        PageSeoFun(TermsCondData?.vSEOTitle,TermsCondData?.vMetaDesc,TermsCondData?.vKeywords)
    }else if(location.pathname=="/privacy-policy"){
        PageSeoFun(PrivacyPolicy?.vSEOTitle,PrivacyPolicy?.vMetaDesc,PrivacyPolicy?.vKeywords)
    }else if(location.pathname=="/covid-19-updates"){
        PageSeoFun(CovidData?.vSEOTitle,CovidData?.vMetaDesc,CovidData?.vKeywords)
    }else if(location.pathname=="/enquiry"){
        PageSeoFun(general.aEnquiryPageArr?.vEnquirySeoTitile,general.aEnquiryPageArr?.vEnquiryMetaDesc,general.aEnquiryPageArr?.vEnquiryKeywords)
    }else{
        PageSeoFun(general?.aProduct?.vProductSeoTitle,general?.aProduct?.vProductMetaDescription,general?.aProduct?.vProductKeywords)
    }

    useEffect(() => {
        toggleMenu();

        window.addEventListener('resize',function(){
            if (window.innerWidth < 1000) {
                setShowNavbar(false);
            }
        });
	}, [])

    const [showNavbar, setShowNavbar] = useState(true);

    const toggleMenu = () => {
        if (window.innerWidth < 1000) {
            setShowNavbar(!showNavbar);
        }else{
            setShowNavbar(true);
        }
    }

    window.addEventListener('resize',function(){
        if (window.innerWidth < 1000) {
            setShowNavbar(false);
        }
    });

    ['load', 'click'].forEach(function(e) {
        window.addEventListener(e,function(){
            const menuBtn = document.getElementById('menu-main');
            if(menuBtn){
                toggleMenu();
            }
        })
    })

    useEffect(() => {
        if(showNavbar){
            document.body.classList.add('menu-show');
        }else{
            document.body.classList.remove('menu-show');
        }
    },[showNavbar])
    
    return (
        <div className="header-main nav-up" id='header-main'>
            <div className="container container-big2">
                <div className="logo-part">
                    <div className="logo"><Link to="/" className="logo-img"><img loading="lazy" className="logo-blue" src={`${process.env.PUBLIC_URL}/images/shiv-om-brass-logo.png`} alt="Shiv Om Brass Industries" /></Link></div>
                    <div className="menu-main">
                        <div className="menu-toggle" onClick={toggleMenu}>
                            <h3 className="menu-tit">Menu</h3>
                            <button className="ToggleMenu MenuButton menu-button" type="button" id="menu-btn2" onClick={toggleMenu}><div className="inn-btn"><span className="fa-line"></span><span className="fa-line2"></span><span className="fa-line3"></span></div></button>
                        </div>
                        {/* <ul id="respMenu" className="ace-responsive-menu" data-menu-style="horizontal"> */}
                        <ul className={`ace-responsive-menu ${showNavbar ? "show-menu" : "hide-menu"}`}>
                            <div className="header-responsive" onClick={toggleMenu}>
                                <div className="logowrap-m"><Link to="/" className="logo-img"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shiv-om-brass-logo.png`} alt="Shiv Om Brass Industries" /></Link></div>
                                <button type="button" className="MenuButton menu-button menu-button-m" id="menu-btn" onClick={toggleMenu}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button>
                            </div>
                            <div className="responsive-menu-inn">
                                <div className="left-menu">
                                    {
                                        Object.keys(general?.vHeader?.vMenuList).map((curEle,index)=>{
                                            const pageName=general?.vHeader?.vMenuList[curEle]['vMenu'];
                                            const vMenuLink=general?.vHeader?.vMenuList[curEle]['vMenuLink'];

                                            return <li key={index} className={location.pathname == vMenuLink || (location.pathname == '' && pageName=="Home") || (location.pathname.includes("/news/") && vMenuLink=="/news-events") || (location.pathname.includes("/product/") && vMenuLink=="/products") || (location.pathname.includes("/sub-product/") && vMenuLink=="/products") ? "active" : ""}><Link  onClick={toggleMenu} to={vMenuLink}><span>{pageName}</span></Link></li>
                                        })
                                    }
                                </div>
                                <div className="right-menu">
                                    {/* <li className="search-menu">
                                        <div className="top-search"><svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M20 20L15.95 15.95M15.95 15.95C16.6 15.2999 17.1157 14.5282 17.4675 13.6789C17.8193 12.8296 18.0003 11.9193 18.0003 11C18.0003 10.0807 17.8193 9.17039 17.4675 8.32107C17.1157 7.47175 16.6 6.70003 15.95 6.04999C15.2999 5.39995 14.5282 4.8843 13.6789 4.5325C12.8296 4.1807 11.9193 3.99963 11 3.99963C10.0807 3.99963 9.17039 4.1807 8.32107 4.5325C7.47175 4.8843 6.70003 5.39995 6.04999 6.04999C4.73717 7.36281 3.99963 9.14338 3.99963 11C3.99963 12.8566 4.73717 14.6372 6.04999 15.95C7.36281 17.2628 9.14338 18.0003 11 18.0003C12.8566 18.0003 14.6372 17.2628 15.95 15.95Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /></svg><input type="search" className="search-field" placeholder="Search" value="" name="s" title="Search" /></div>
                                    </li> */}
                                    <li className="enquiry-menu">
                                        <Link className="enquiry-btn" to={general.vHeader.vHEnquiryBtnLink}>
                                            <span>{general.vHeader.vHEnquiryBtnLabel}</span>
                                        </Link>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header