import { useNavigate } from 'react-router-dom';
import product from '../json/product.json'
import productCategory from '../json/product_category.json'
import Slider from 'react-slick';
import { UploadUrl } from '../../Services/CommonUrl';
import { scrollBottomToTop } from './Functions';

function ProductSliderCommon(props) {
    const navigate=useNavigate();

    const HomeProductSlider = {
        slidesToShow: 4,
		slidesToScroll: 4,
		pauseOnHover:false,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		arrows: true,
		variableWidth: true,
        adaptiveHeight:true,
		infinite: true,
        nextArrow: <ProductNextArrow />,
        prevArrow: <ProductPrevArrow />,
        responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					variableWidth: false,
					dots: false,
				}
			},
		 	{
			  breakpoint: 768,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				variableWidth: false,
				dots: false,
			  }
			},
			{
			  breakpoint: 600,
			  settings: {
				 variableWidth: false,
				 slidesToShow: 1,
				 slidesToScroll: 1,
				 dots: false,
			  }
			}
		]
    };

    const returnCategoryName = (iCategoryId) => {
        const getRecord = productCategory.filter((curEle, index) => {
            return curEle.iCategoryId == iCategoryId
        })
        return getRecord.length > 0 ? getRecord[0]['iCategoryName'] : ""
    }

    const productDetailPage = (name,isSubProduct=0) =>{
        if(props.isProdDetailPage){
            scrollBottomToTop();
        }
        const b = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        if(isSubProduct==0){
            navigate(`/product/${b}`);
        }else{
            navigate(`/sub-product/${b}`);
        }
    };

  return (
        <Slider {...HomeProductSlider}>
            {
                product.map((curEle, index) => {
                    const filterSubProduct=product.filter((curData,index)=>{
                        return curData.iParentProdId==curEle.iProductId
                    })
                    return curEle.vProductStatus == "Active" && curEle.iParentProdId==""? <div className="product-item" key={index}>
                        <div className="product-inn">
                        <a href='javascript:void(0)' onClick={()=>productDetailPage(curEle.vProductName+'-'+returnCategoryName(curEle.iCategoryId),filterSubProduct.length)} className="product-link" >
                                <div className="product-img">
                                    <img loading="lazy" src={UploadUrl + 'product/' + curEle.vProductImage} alt={curEle.vImageAltText} title={curEle.vImageTitleText} />
                                </div>
                                <h4 className="product-name">
                                    <span>{curEle.vProductName} <br />{returnCategoryName(curEle.iCategoryId)}</span>
                                </h4>
                            </a>
                        </div>
                    </div> : ""
                })
            }
        </Slider>
  )
}

export default ProductSliderCommon

const ProductNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className="slide-arrow next-arrow slick-arrow"><svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="24" height="24" viewBox="-5.5 -1 24 24"><path fill="currentColor" d="M2.214 1c.366 0 .731.14 1.011.419l8.571 8.571a1.43 1.43 0 0 1 0 2.021l-8.571 8.571a1.43 1.43 0 0 1-2.021-2.021L8.766 11 1.204 3.438A1.428 1.428 0 0 1 2.214 1z" /></svg></button>
    );
}

const ProductPrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className="slide-arrow prev-arrow slick-arrow"><svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="24" height="24" viewBox="-5.5 -1 24 24"><path fill="currentColor" d="M10.786 21c-.366 0-.731-.14-1.011-.419L1.204 12.01a1.428 1.428 0 0 1 0-2.02l8.571-8.571a1.43 1.43 0 0 1 2.021 2.02L4.234 11l7.562 7.562A1.428 1.428 0 0 1 10.786 21z" /></svg></button>
    );
}