import Slider from 'react-slick'
import indusustries from '../json/industries.json';

function HomeIndustriesComp() {
    const HomeIndustriesSlider = {
        slidesToShow: 7,
		slidesToScroll: 2,
		pauseOnHover:false,
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		arrows: true,
		variableWidth: true,
		infinite: true,
		adaptiveHeight: true,
        nextArrow: <ProductNextArrow />,
        prevArrow: <ProductPrevArrow />,
        responsive: [
			{
				breakpoint: 999,
				settings: {
					arrows: true,
					slidesToShow: 4,
					slidesToScroll: 1,
				}
			},
		 	{
			  breakpoint: 768,
			  settings: {
				arrows: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				dots: false,
			  }
			},
			{
			 breakpoint: 600,
			 settings: {
				variableWidth: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
			 }
		  }
		]
    }

  return (
    <Slider {...HomeIndustriesSlider}>
        {
            indusustries.map((curEle, index) => {
                return <div className="industries-item" key={index}>
                    <div className="industries-image">
                        <img loading="lazy" className="industries" src={curEle?.vImage} alt={curEle?.vImageAltText} title={curEle?.vImageTitleText} />
                        <div className="caption">
                            <h2 className="tit">{curEle?.vIndustryName}</h2>
                        </div>
                    </div>
                </div>
            })
        }
    </Slider>
  )
}

export default HomeIndustriesComp

const ProductNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className="slide-arrow next-arrow slick-arrow"><svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="24" height="24" viewBox="-5.5 -1 24 24"><path fill="currentColor" d="M2.214 1c.366 0 .731.14 1.011.419l8.571 8.571a1.43 1.43 0 0 1 0 2.021l-8.571 8.571a1.43 1.43 0 0 1-2.021-2.021L8.766 11 1.204 3.438A1.428 1.428 0 0 1 2.214 1z" /></svg></button>
    );
}

const ProductPrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className="slide-arrow prev-arrow slick-arrow"><svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="24" height="24" viewBox="-5.5 -1 24 24"><path fill="currentColor" d="M10.786 21c-.366 0-.731-.14-1.011-.419L1.204 12.01a1.428 1.428 0 0 1 0-2.02l8.571-8.571a1.43 1.43 0 0 1 2.021 2.02L4.234 11l7.562 7.562A1.428 1.428 0 0 1 10.786 21z" /></svg></button>
    );
}