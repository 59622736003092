import React, { useEffect, useState } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import CreerData from './json/career.json';
import axios from 'axios';
import { MailUrl } from '../Services/CommonUrl';
var validator = require('validator');

function Career() {
    useEffect(()=>{
        scrollBottomToTop();
    },[])

    const [isMailSend,setisMailSend]=useState(false);
    const [form,setForm]=useState({
        vName:'',
        vPhoto:'',
        vPhotoName:'',
        vEmail:'',
        vPhoneNumber:'',
        vCity:'',
        vState:'',
        vAttachCV:'',
        vAttachCVName:'',
        vMessage:'',
    });

    const [errorMessageArr,setErrorMessageArr]=useState({
        vName:'',
        vPhoto:'',
        vEmail:'',
        vPhoneNumber:'',
        vCity:'',
        vState:'',
        vAttachCV:'',
        vMessage:'',
    })

    const [isSubmitErr,setIsSubmitErr]=useState(false);
    const [errorSendMail,setErrorSendMail]=useState("");
    function sendEmail() {
        const filterReq=Object.keys(errorMessageArr).filter((curEle,index)=>{
            return errorMessageArr[curEle]!=""
        })

        const filterTotalFil=Object.keys(form).filter((curEle,index)=>{
            return errorMessageArr[curEle]==""
        })

        if(filterReq.length==0 && filterTotalFil.length){
            axios.post(MailUrl, {
                action:"careerMail",
                data:form
              })
              .then(function (response) {
                if(response.data.status==200){
                    setForm({
                        vName:'',
                        vPhoto:'',
                        vPhotoName:'',
                        vEmail:'',
                        vPhoneNumber:'',
                        vCity:'',
                        vState:'',
                        vAttachCV:'',
                        vAttachCVName:'',
                        vMessage:'',
                    });
                    setisMailSend(true);
                    setIsSubmitErr(false);
                    setErrorSendMail("");
                }else{
                    if(response?.data?.status==412){
                        setErrorSendMail(response?.data?.msg);
                    }
                    setForm({
                        vName:'',
                        vPhoto:'',
                        vPhotoName:'',
                        vEmail:'',
                        vPhoneNumber:'',
                        vCity:'',
                        vState:'',
                        vAttachCV:'',
                        vAttachCVName:'',
                        vMessage:'',
                    });
                    setisMailSend(false);
                }
              })
              .catch(function (error) {
                // console.log(error)
              });
        }else{
            setIsSubmitErr(true);
        }
    }

    const [selectedFile, setSelectedFile] = useState(null);

    console.log("selectedFile",selectedFile)
    useEffect(()=>{
        if(form.vName==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vName']:'Name is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vName']:''
                }
            })
        }

        if(form.vPhoto==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vPhoto']:'Photo is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vPhoto']:''
                }
            })
        }

        if(form.vEmail==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vEmail']:'Email is required!'
                }
            })
        }else{
            if(!validator.isEmail(form.vEmail)){
                setErrorMessageArr(prevState=>{
                    return{
                        ...prevState,
                        ['vEmail']:'Email is not valid!'
                    }
                })
            }else{
                setErrorMessageArr(prevState=>{
                    return{
                        ...prevState,
                        ['vEmail']:''
                    }
                })
            }
        }

        if(form.vPhoneNumber==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vPhoneNumber']:'Phone Number is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vPhoneNumber']:''
                }
            })
        }

        if(form.vCity==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vCity']:'City is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vCity']:''
                }
            })
        }

        if(form.vState==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vState']:'State is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vState']:''
                }
            })
        }

        if(form.vAttachCV==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vAttachCV']:'Attach CV is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vAttachCV']:''
                }
            })
        }

        if(form.vMessage==""){
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vMessage']:'Messsage is required!'
                }
            })
        }else{
            setErrorMessageArr(prevState=>{
                return{
                    ...prevState,
                    ['vMessage']:''
                }
            })
        }
    },[form])

    const handleChange=(e,file="")=>{
        if(file==""){
            setForm(prevState=>{
                return{
                    ...prevState,
                    [e.target.name]:e.target.value
                }
            });
        }else{
            const file = e.target.files[0];

            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    const isFileExt = (file) => {
                        const splitArr=file?.split("/")
                        return splitArr[1];
                    };

                    if(e.target.name=="vPhoto" && file.size <= 2 * 1024 * 1024 && (isFileExt(file.type)=="jpg" || isFileExt(file.type)=="jpeg" || isFileExt(file.type)=="png")){
                        setSelectedFile(file);
                        setForm(prevState=>{
                            return{
                                ...prevState,
                                [e.target.name]:base64String,
                                vPhotoName:file.name,
                            }
                        });
                    }else if(e.target.name=="vAttachCV" && file.size <= 5 * 1024 * 1024 && (isFileExt(file.type)=="jpg" || isFileExt(file.type)=="jpeg" || isFileExt(file.type)=="png" || isFileExt(file.type)=="docx" || isFileExt(file.type)=="doc" || isFileExt(file.type)=="pdf")){
                        setSelectedFile(file);
                        setForm(prevState=>{
                            return{
                                ...prevState,
                                [e.target.name]:base64String,
                                vAttachCVName:file.name,
                            }
                        });
                    }else{
                        if(e.target.name=="vPhoto"){
                            if(file.size > 2 * 1024 * 1024){
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vPhoto']:'File max upload limit is 2MB.'
                                    }
                                })
                            }else if(isFileExt(file.type)!="jpg" && isFileExt(file.type)!="jpeg" && isFileExt(file.type)!="png"){
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vPhoto']:'File format not valid.'
                                    }
                                })
                            }else{
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vPhoto']:''
                                    }
                                })
                            }
                            return false;
                        }else if(e.target.name=="vAttachCV"){
                            if(file.size > 5 * 1024 * 1024){
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vAttachCV']:'File max upload limit is 2MB.'
                                    }
                                })
                            }else if(isFileExt(file.type)!="jpg" && isFileExt(file.type)!="jpeg" && isFileExt(file.type)!="png" && isFileExt(file.type)!="docx" && isFileExt(file.type)!="doc" && isFileExt(file.type)!="pdf"){
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vAttachCV']:'File format not valid.'
                                    }
                                })
                            }else{
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vAttachCV']:''
                                    }
                                })
                            }
                            return false;
                        }
                    }
                };

                reader.readAsDataURL(file);
            }
        }
    }

    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white career-top-section content-section">
                    <div className="container container-big">
                        <div className="career-top">
                            <div className="career-top-list left">
                                {
                                    CreerData?.section1?.vImage ?
                                        <img loading="lazy" className="career-img" src={CreerData?.section1?.vImage} alt={CreerData?.section1?.vImageAltText} title={CreerData?.section1?.vImageTitleText} />
                                    : ''
                                }
                            </div>
                            <div className="career-top-list right">
                                <div dangerouslySetInnerHTML={{__html:CreerData?.section1?.vHeading}}></div>
                                <div dangerouslySetInnerHTML={{__html:CreerData?.section1?.vParagraph}}></div>
                                <div className="join-us-btn">
                                    <a className="btn JoinUs" href="javascript:;">{CreerData?.section1?.vBtnLabel}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white quality-services-section">
                    <div className="container container-big">
                        <div className="quality-services">
                            <div className="quality-services-list left">
                                <h5 className="tit2">{CreerData?.section2?.vTitle}</h5>
                            </div>
                            <div className="quality-services-list right">
                                <div className="desc">{CreerData?.section2?.vText}</div>
                                <ul className="quality-list">
                                    {
                                        Object.keys(CreerData?.section2?.vList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(CreerData?.section2?.vList).map((curEle, i) => {
                                                        const list = CreerData?.section2?.vList[curEle];
                                                        return (
                                                            <li key={i}>
                                                                {
                                                                    list?.vImage ?
                                                                        <img loading="lazy" src={list?.vImage} alt={list?.vImageAltText} title={list?.vImageTitleText} />
                                                                        : ''
                                                                }
                                                                {list?.vListName}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white content-section culture-life-section">
                    <div className="container container-big">
                        <div className="culture-life">
                            <div className="culture-life-list left">
                                <div dangerouslySetInnerHTML={{__html:CreerData?.section3?.vTitle}}></div>
                                <div className="culture-life-photo">
                                    <div className="list left">
                                        {
                                            CreerData?.section3?.imageList[1]['vImage']?
                                                <img loading="lazy" src={CreerData?.section3?.imageList[1]['vImage']} alt={CreerData?.section3?.imageList[1]['vImageAltText']} title={CreerData?.section3?.imageList[1]['vImageTitleText']} />
                                            : ''
                                        }
                                    </div>
                                    <div className="list right">
                                        {
                                            CreerData?.section3?.imageList[2]['vImage']?
                                                <img loading="lazy" src={CreerData?.section3?.imageList[2]['vImage']} alt={CreerData?.section3?.imageList[2]['vImageAltText']} title={CreerData?.section3?.imageList[2]['vImageTitleText']} />
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="culture-life-photo">
                                    <div className="list full">
                                        {
                                            CreerData?.section3?.imageList[3]['vImage'] ?
                                                <img loading="lazy" src={CreerData?.section3?.imageList[3]['vImage']} alt={CreerData?.section3?.imageList[3]['vImageAltText']} title={CreerData?.section3?.imageList[3]['vImageTitleText']} />
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="culture-life-photo">
                                    <div className="list right">
                                        {
                                            CreerData?.section3?.imageList[4]['vImage'] ?
                                                <img loading="lazy" src={CreerData?.section3?.imageList[4]['vImage']} alt={CreerData?.section3?.imageList[4]['vImageAltText']} title={CreerData?.section3?.imageList[4]['vImageTitleText']} />
                                            : ''
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="culture-life-list right">
                                <h4 className="tit2" dangerouslySetInnerHTML={{__html:CreerData?.section3?.vHeadingParagraph}}></h4>
                                <ul className="list01 pdb-0">
                                    {
                                        Object.keys(CreerData?.section3?.vCultureList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(CreerData?.section3?.vCultureList).map((curEle, i) => {
                                                        const list = CreerData?.section3?.vCultureList[curEle];
                                                        return (
                                                            <li key={i}>
                                                                {list}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                </ul>
                                <div className="perks-benefits-sec">
                                    <h6 className="tit">{CreerData?.section3?.vHeadingBenefit}</h6>
                                    <ul className="perks-benefits-list">
                                        {
                                            Object.keys(CreerData?.section3?.vBenefitList).length > 0 ?
                                                <>
                                                    {
                                                        Object.keys(CreerData?.section3?.vBenefitList).map((curEle, i) => {
                                                            const list = CreerData?.section3?.vBenefitList[curEle];
                                                            return (
                                                                <li key={i}><div className="list">{list}</div></li>
                                                            )
                                                        })
                                                    }
                                                </>
                                                : ''
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="JoinUs" className="section-white apply-now-section">
                    <div className="container container-big">
                        <div className="apply-now-sec">
                            <div className="apply-now-list left">
                                <h3 className="tit">{CreerData?.section4?.vTitle}</h3>
                                <ul className="hiring-process">
                                    {
                                        Object.keys(CreerData?.section4?.vHiringProcessList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(CreerData?.section4?.vHiringProcessList).map((curEle, i) => {
                                                        const list = CreerData?.section4?.vHiringProcessList[curEle];
                                                        return (
                                                            <li key={i}><span className="num">{Number(i)+1}</span>{list}</li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                    {/* <li><span className="num">1</span>Initial Interaction</li>
                                    <li><span className="num">2</span>Technical Interview</li>
                                    <li><span className="num">3</span>Real-time Assignment</li>
                                    <li><span className="num">4</span>Final HR Discussion</li>
                                    <li><span className="num">5</span>Offer Rollout!</li> */}

                                </ul>
                                <div className="contact-hr">
                                    <h3 className="tit">{CreerData?.section4?.vHeading}</h3>
                                    <div className="desc">
                                        <span dangerouslySetInnerHTML={{__html: CreerData?.section4?.vText}}></span> <a href={`mailto:${CreerData?.section4?.vEmail}`}>{CreerData?.section4?.vEmail}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="apply-now-list right">
                                <div className="apply-now-form">
                                    <h4 className="tit2">Apply Now</h4>
                                    <div className="field-row">
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">Your Name<span>*</span></label>
                                                <input className="form-control" onChange={handleChange} type="text" id="vName" name="vName" value={form.vName} />
                                                {
                                                    isSubmitErr && errorMessageArr.vName!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vName}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">Your Photo (.jpg,.jpeg,.png | 2MB)</label>
                                                <input className="form-control" onChange={(e)=>handleChange(e,1)} type="file" id="vPhoto" name="vPhoto" />
                                                {
                                                    isSubmitErr && errorMessageArr.vPhoto!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vPhoto}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">Your Email<span>*</span></label>
                                                <input className="form-control" onChange={handleChange} type="text" id="vEmail" name="vEmail" value={form.vEmail} />
                                                {
                                                    isSubmitErr && errorMessageArr.vEmail!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vEmail}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">Phone Number<span>*</span></label>
                                                <input className="form-control" type="text" id="vPhoneNumber" name="vPhoneNumber" onChange={handleChange} value={form.vPhoneNumber}/>
                                                {
                                                    isSubmitErr && errorMessageArr.vPhoneNumber!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vPhoneNumber}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">City<span>*</span></label>
                                                <input className="form-control" onChange={handleChange} type="text" id="vCity" name="vCity" value={form.vCity}/>
                                                {
                                                    isSubmitErr && errorMessageArr.vCity!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vCity}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">State<span>*</span></label>
                                                <input className="form-control" onChange={handleChange} type="text" id="vState" name="vState" value={form.vState} />
                                                {
                                                    isSubmitErr && errorMessageArr.vState!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vState}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2">
                                            <div className="form-group">
                                                <label className="control-label">Attach CV<span>*</span> (.jpg,.jpeg,.png,.docx,.doc,.pdf | 5MB)</label>
                                                <input className="form-control" onChange={(e)=>handleChange(e,1)} type="file" id="vAttachCV" name="vAttachCV" />
                                                {
                                                    isSubmitErr && errorMessageArr.vAttachCV!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vAttachCV}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                        <div className="form-field2 full">
                                            <div className="form-group">
                                                <label className="control-label">Your Message</label>
                                                <textarea name="vMessage" onChange={handleChange} id="vMessage" className="form-control">{form.vMessage}</textarea>
                                                {
                                                    isSubmitErr && errorMessageArr.vMessage!=""?
                                                    <div className="invalid-feedback2">{errorMessageArr.vMessage}</div>
                                                    :""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-submit">
                                        <button className="btn" type="submit" onClick={sendEmail}>Submit</button>
                                    </div>
                                    {
                                        isMailSend || errorSendMail!=""?
                                        <>
                                        {
                                            isMailSend?
                                            <div className="alert alert-success">Thank you for your message. It has been sent.</div>
                                            :''
                                        }
                                        {
                                            errorSendMail!=""?
                                            <div className="alert alert-danger">{errorSendMail}</div>
                                            :""
                                        }
                                        </>:''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default Career