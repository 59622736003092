import React, { useEffect } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import data from './json/covid.json';

function Covid() {
    useEffect(()=>{
        scrollBottomToTop();
    },[])

    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white comman-header-section pdb-0">
                    <img loading="lazy" className="header-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container">
                        <div className="section-title2">
                            <h2 className="title"><span className="sub-title">{data.vTitle}</span>{data.vHeading}</h2>
                            <div className="desc">{data.vParagraph}</div>
                        </div>
                    </div>
                </div>
                <div className="section-white content-section terms-and-conditions-section">
                    <div className="container">
                        <p>Throughout all the recent development, Shiv Om Brass Industries is working hard to support the commitments we made to support your business. We are very focused on protecting the health and safety of our employees while managing the ability to keep serving you better.</p>
                        <h2 className="tit padb-25">Following are Some Several Internal Rules to stay our employee safe</h2>
                        <ul className="list01">
                            <li>Keep maintaining social distancing.</li>
                            <li>Improving personal hygiene essentials like Hand sanitizer, Gloves &amp; Facemasks are mandatory for all the employees.</li>
                            <li>Routine Health and Temperature Screenings for everybody entering our manufacturing facilities.</li>
                            <li>If any of our employees highlights a symptom with cold or flu are sent home immediately and suggest to test quickly.</li>
                            <li>Sharing information to help and prevent the spread of COVID-19.</li>
                        </ul>
                        <p>We realize that you depend on us, that's why we try to assure our customers and associates for providing our manufacturing facilities with the best quality to fulfil our commitment.</p>
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default Covid