import React, { useEffect } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import data from './json/privacypolicy.json';

function PrivacyPolicy() {
    useEffect(()=>{
        scrollBottomToTop();
    },[])

    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white comman-header-section pdb-0">
                    <img loading="lazy" className="header-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container">
                        <div className="section-title2">
                            <h2 className="title"><span className="sub-title">{data.vTitle}</span>{data.vHeading}</h2>
                            <div className="desc">{data.vParagraph}</div>
                        </div>
                    </div>
                </div>
                <div className="section-white content-section terms-and-conditions-section">
                    <div className="container">
                        <h2 className="tit padb-25">1. Basic information</h2>
                        <ul className="list01">
                            <li>In compliance with the general provision of European Regulation for the protection of personal data we provide useful information regarding the processing of the personal data provided. The information is provided to the art. 13 of EU Reg. 2016/679 (European regulation for the protection of personal data) and is also inspired by the provisions of Directive 2002/58 / EC, as updated by Directive 2009/136 / EC, concerning Cookies and the Provision of the Authority for the protection of personal data of 08.05.2014 regarding cookies. Treatable personal data: personal data: any information concerning an identified or identifiable natural person (involved); the natural person who can be identified is considered identifiable, directly or indirectly, with particular reference to an identifier such as the name, an identification number, location data, an online identification or to one or more characteristic elements of his physical, physiological, economic, cultural or social identity. (C26, C27, C30)</li>
                            <li>When you use our services, you need to trust us with your data. This Privacy Policy is meant to help you understand what information we gather, why we get it, and what we do with it. When you share information with us, we can make our services even better for you. As you use our services, we need you to be clear about how we're using information and how you can protect your privacy. This is significant; we trust you will set aside the effort to peruse it cautiously. Keep in mind, you can find controls to maintain your data and protect your privacy and security. We've tried to keep it as basic as could be expected.</li>
                        </ul>
                        <h2 className="tit padb-25">2. Management of private information</h2>
                        <ul className="list01">
                            <li>You can see or alter your information online for large numbers of our services. You can likewise settle on decisions about our assortment and utilization of your information. How you can access or control your information will rely upon which services you use. You can pick whether you wish to get promotional information from our site by email, SMS, and phone. If you want to get a promotional email or SMS from us and would like to opt out, you can do. You can likewise settle on decisions about to get a promotional email, calls, and postal mail by visiting and marking into Company Promotional Communications Manager, which permits you to refresh contact data, oversee contact choices, quit email memberships, and pick whether to impart your contact data to our partners.</li>
                        </ul>
                        <h2 className="tit padb-25">3. Information We Gather</h2>
                        <ul className="list01">
                            <li>Our website gathers information to work well and provide you with the best experiences with our services. You give a piece of this information straightforwardly, for instance, when you make a personal record. We get some of it by recording how you communicate with our services by, for instance, using technologies like cookies, and getting error reports or utilization information from programming running on your gadget. We additionally obtain information from third parties. For instance, we improve the information we gather by buying segment information from different organizations. We additionally use services from different organizations to assist us with deciding an area dependent on your IP address to modify certain services to your area. The information we gather relies upon the services and features you use.</li>
                        </ul>
                        <h2 className="tit padb-25">4. How We Use Your Information</h2>
                        <ul className="list01">
                            <li>Our site uses the data we collect for three primary purposes: to conduct our company and provide the services we offer, to send information, including promotional information, and to display advertising. In carrying out these purposes, we combine data we obtain through the different services you use to give you a more seamless, uniform, and personalized experience.</li>
                        </ul>
                        <h2 className="tit padb-25">5. Share Your Data</h2>
                        <ul className="list01">
                            <li>We share your information with your assent or as essential to complete any exchange, delivery or export any service you have mentioned or approved. For instance, we share your content with outsiders when you advise us to do as such. At the point when you give payment information to make a buy, we will share payment information with banks and other things that require for payment transactions or provide other commercial services, and for fraud prevention and credit risk loss.</li>
                            <li>Furthermore, we divide personal information between our controlled offshoots and subsidiaries. We likewise share individual information with merchants or specialists chipping away at our sake for the reasons depicted in this articulation. For instance, organizations we've recruited to give client care backing or help to defend and securing our systems and services may require admittance to individual information to give those capacities. In such cases, these organizations should keep our information protection and security necessities and are not permitted to utilize personal information they get from us for some other reason. We may also reveal private information as part of a corporate event such as a merger or sale of assets.</li>
                            <li>If you have any questions about this privacy policy or our treatment of your information, feel free to <a href="contact-us/">contact us</a>.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default PrivacyPolicy