import React from 'react'
import Script from './ScriptRun';

function AppendScript() {
    return (
        <>
            <Script src="./js/generaljsnew.js" type="text/javascript" />
        </>
    )
}

export default AppendScript