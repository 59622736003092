import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { MailUrl } from '../../Services/CommonUrl';

function DownloadBrochureModal(props) {
    const [form,setForm]=useState({
        vEmail:"",
        vPhoneNumber:""
    });

    const handleChange=(e)=>{
        setForm(prevState=>{
            return{
                ...prevState,
                [e.target.name]:e.target.value
            }
        });
    }

    const [isErrorSubmit,setIsErrorSubmit]=useState(false);
    const [isSuccessMessage,setIsSuccessMessage]=useState(false);
    const [errorSendMail,setErrorSendMail]=useState("");
    const onSubmitForm=()=>{
        if(form.vEmail=="" || form.vPhoneNumber==""){
            setIsErrorSubmit(true);
            setIsSuccessMessage(false);
        }else{
            axios.post(MailUrl, {
                action:"brochureMail",
                data:form
              })
              .then(function (response) {
                if(response?.data?.status==200){
                    setForm({
                        vEmail:"",
                        vPhoneNumber:""
                    });
                    setIsErrorSubmit(false);
                    setIsSuccessMessage(true);
                    setModalIsOpen(false);
                    setErrorSendMail("");
                }else{
                    if(response?.data?.status==412){
                        setErrorSendMail(response?.data?.msg);
                    }
                }
              })
              .catch(function (error) {
                // console.log(error)
              });
        }
    }

    
    const [modalIsOpen,setModalIsOpen]=useState(false);
    
    useEffect(()=>{
        if(props.show){
            setModalIsOpen(true);
        }else{
            setModalIsOpen(false);
        }
    },[props])

    const closeModal=()=>{
        setModalIsOpen(false);
    }

    return (
        <div className="modal download-brochure-modal fade" id="ModalDownloadBrochure" data-bs-backdrop="static" data-bs-keyboard="true" tabIndex="-1" aria-labelledby="ModalDownloadBrochureLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="ModalDownloadBrochureLabel">Request Brochure</h5>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather icon feather-x"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></button>
                    </div>
                    <div className="modal-body">
                        <div className='download-brochure-modal'>
                            <div className="pd-20">
                                <div className="download-pdf-desc">Please provide your email address. We will send you copy of our brochure in a PDF format.</div>
                                <div className="form-group">
                                    <label className="control-label">Your Email<span>*</span></label>
                                    <input className="form-control" type="text" id="vEmail" name="vEmail" value={form.vEmail} onChange={handleChange} />
                                    {
                                        isErrorSubmit && form.vEmail == "" ?
                                            <div className="invalid-feedback2">Email is required!</div>
                                            : ""
                                    }
                                </div>
                                <div className="form-group">
                                    <label className="control-label">Phone Number<span>*</span></label>
                                    <input className="form-control" type="text" id="vPhoneNumber" name="vPhoneNumber" onChange={handleChange} value={form.vPhoneNumber} />
                                    {
                                        isErrorSubmit && form.vPhoneNumber == "" ?
                                            <div className="invalid-feedback2">Phone Number is required!</div>
                                            : ""
                                    }
                                </div>
                            </div>
                            <div className="custom-modal-footer">
                                <div className="modal-l-f">
                                    <button type="button" className="btn" onClick={onSubmitForm}>Submit</button>
                                </div>
                            </div>
                            {
                                isSuccessMessage || errorSendMail!=""?
                                <>
                                {
                                    isSuccessMessage?
                                    <div className="alert alert-success">Thank you for your message. It has been sent.</div>
                                    :''
                                }
                                {
                                    errorSendMail!=""?
                                    <div className="alert alert-danger">{errorSendMail}</div>
                                    :""
                                }
                                </>:''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadBrochureModal