import React, { useEffect, useState } from 'react'
import CommonLayout from './Common/CommonLayout'
import Slider from 'react-slick';
import { scrollBottomToTop } from './Common/Functions';
import {useLocation,Link} from 'react-router-dom';
import product from './json/product.json';
import productCategory from './json/product_category.json'
import { UploadUrl } from '../Services/CommonUrl';
import DunsCommon from './Common/DunsCommon';
import HomeIndustriesComp from './Common/HomeIndustries';
import ProductSliderCommon from './Common/ProductSliderCommon';

function ProductDetail() {
    const location = useLocation();

    const returnCategoryName = (iCategoryId) => {
        const getRecord = productCategory.filter((curEle, index) => {
            return curEle.iCategoryId == iCategoryId
        })
        return getRecord.length > 0 ? getRecord[0]['iCategoryName'] : ""
    }

    const ProductSlider={
        slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover:false,
		autoplay: true,
		autoplaySpeed: 2000,
		arrows: false,
		dots: true,
    }

    useEffect(()=>{
        scrollBottomToTop();
    },[])

    const filterData=product.filter((curEle,index)=>{
        const categoryName=returnCategoryName(curEle.iCategoryId)
        const urlName=curEle.vProductName+'-'+categoryName;
        const b = urlName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        return "/product/"+b==location.pathname
    })
    
    const productData=filterData.length>0?filterData[0]:{};
    
    if(filterData.length==0){
        window.location.href = "/";
    }

    const filterImageGallery=productData?.product_image_gallery && Object.keys(productData?.product_image_gallery).filter((curEle,index)=>{
        const imgUrl=productData?.product_image_gallery[curEle];
        return imgUrl?.vImage!="" && imgUrl?.vImage!=null
    })

    const [dunAccess,setDunAccess]=useState("");
    const handleGetData=(data)=>{
        setDunAccess(data);
    }
    
    return (
        <CommonLayout handleGetData={handleGetData}>
            <div className="content-main sub-page">
                <div className="section-white product-detail-section">
                    <div className="container container-big">
                        <h2 className="product-title"><span className="sub-tit">Products</span>{productData?.vProductName} {returnCategoryName(productData?.iCategoryId)}</h2>
                        <div className="product-detail">
                            <div className="product-detail-list left">
                                {
                                    productData?.product_image_gallery && filterImageGallery.length>0?
                                    <div className="ProductSlider">
                                        <Slider {...ProductSlider}>
                                            {
                                                Object.keys(productData?.product_image_gallery).map((curEle,index)=>{
                                                   const productGalleryImg=productData?.product_image_gallery[curEle];
                                                    return <div key={index} className="product-item"><img loading="lazy" className="products-img" src={productGalleryImg?.vImage} alt={productGalleryImg?.vImageAltText} title={productGalleryImg?.vImageTitleText} /></div>
                                                })
                                            }
                                        </Slider>
                                    </div>
                                    :<img loading="lazy" className="products-img" src={UploadUrl + 'product/' +productData.vProductImage} alt={productData?.vImageAltText} title={productData?.vImageTitleText} />
                                }
                            </div>
                            <div className="product-detail-list right">
                                <div className="product-detail-inn">
                                    <div className="product-desc" dangerouslySetInnerHTML={{__html:productData?.vProduct_detail}}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    productData?.vExtraProdDetail?.length>0?
                    <div className="section-white product-decription-section">
                        <div className="container container-big">
                            <div className="product-decription">
                                <div className="product-decription-list left">
                                    <div className="product-decription-inn">
                                        <h3 className="tit">Overall Details of {productData?.vProductName} {returnCategoryName(productData?.iCategoryId)}</h3>
                                        <div className="note">
                                            <h3 className="tit3">Note</h3>
                                            To know more about the products and services, please submit your business 
                                             <Link to="/enquiry" target="_blank"> enquiries</Link> today and we'll get back to you. <Link to="/contact-us" target="_blank">Contact Us Now</Link>.
                                        </div>
                                    </div>
                                </div>
                                <div className="product-decription-list right">
                                    <ul className="right-list">
                                        {
                                            productData.vExtraProdDetail.map((curEle,index)=>{
                                                return <li key={index}>
                                                            <h3 className="tit2">{curEle.vProduct_category}</h3>
                                                            {curEle.vProduct_desc}
                                                        </li>
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    :""
                }
                <div className="section-white home-product-section pdb-0">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">Products</h3>
                            <div className="desc">Looking for the Best Brass Products? Explore the wide range of Products created for different Industry Segments.</div>
                        </div>
                        {
                            product.length?
                            <div className="HomeProductSlider">
                                <ProductSliderCommon isProdDetailPage={true}/>
                            </div>
                            :""
                        }
                        <div className="view-all-product">
                            <Link to="/products" className="btn-outline hvr-sweep-to-top">
                                 View All
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="section-white home-industries-section pdb-0">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">Industries We Serve</h3>
                            <div className="desc">Every Industry requires a custom solution, one standard doesn't fit for all. Shiv Om Brass serves businesses across a wide range of Industries.</div>
                        </div>
                        <div className="HomeIndustriesSlider">
                            <HomeIndustriesComp/>
                        </div>
                    </div>
                </div>
                <div className="section-white home-bottom-section about-certificate3-section">
                    <div className="container container-big">
                        <DunsCommon dunAccess={dunAccess}/>
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default ProductDetail
