import React, { useEffect, useRef, useState } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import QualityData from './json/quality.json';
import product from './json/product.json';
import productCategory from './json/product_category.json';
import { UploadUrl } from '../Services/CommonUrl';
import { Link, useNavigate } from 'react-router-dom';
import QualityDunsCommon from './Common/QualityDunsCommon';
import adboutData from './json/about.json'
import AboutInfraStructureComp from './Common/AboutInfraStructureComp';

function Quality() {
    const navigate=useNavigate();

    useEffect(() => {
        scrollBottomToTop();
    }, [])

    const returnCategoryName = (iCategoryId) => {
        const getRecord = productCategory.filter((curEle, index) => {
            return curEle.iCategoryId == iCategoryId
        })
        return getRecord.length > 0 ? getRecord[0]['iCategoryName'] : ""
    }

    const productDetailPage = (name,isSubProduct=0) =>{
        const b = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        if(isSubProduct==0){
            navigate(`/product/${b}`);
        }else{
            navigate(`/sub-product/${b}`);
        }
    };

    const [dunAccess,setDunAccess]=useState("");
    const handleGetData=(data)=>{
        setDunAccess(data);
    }

    return (
        <CommonLayout handleGetData={handleGetData}>
            <div className="content-main sub-page">
                <div className="section-white quality-top-section pdb-0">
                    <img loading="lazy" className="quality-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-quality-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container container-big">
                        <div className="quality-top-inn">
                            <div className="quality-list left">
                                <div className="section-title3">
                                    <h2 className="title"><span className="sub-title">{QualityData?.section1?.vTitle}</span>{QualityData?.section1?.vHeading}</h2>
                                    <div className="desc">{QualityData?.section1?.vHeadParagraph}</div>
                                </div>
                                <ul className="quality-certified">
                                    <li className="name">We are Certified with</li>
                                    <li><img loading="lazy" src={adboutData?.section1?.imageList[1]['vImage']} title={adboutData?.section1?.imageList[1]['ImageTitleText']} alt={adboutData?.section1?.imageList[1]['imageAltText']} /></li>
                                    <li><img loading="lazy" src={adboutData?.section1?.imageList[2]['vImage']} title={adboutData?.section1?.imageList[2]['ImageTitleText']} alt={adboutData?.section1?.imageList[2]['imageAltText']} /></li>
                                </ul>
                            </div>
                            <div className="quality-list right">
                                <div className="top-video">
                                    {
                                        QualityData?.section1?.vVideo ?
                                            <video className="quality-video" autoPlay loop muted preload="metadata" poster={adboutData?.section1?.videoThumbnailList && adboutData?.section1?.videoThumbnailList.length>0?adboutData?.section1?.videoThumbnailList[1]:""}>
                                                <source id="mp4" src={adboutData?.section1?.videoList[1]} type="video/mp4" />
                                            </video>
                                            : ''
                                    }
                                </div>
                                <div className="quality-bottom-image">
                                    <div className="list left">
                                        {
                                            QualityData?.section1?.vImage1 ?
                                                <li><img loading="lazy" className='quality-img' src={QualityData?.section1?.vImage1} title={QualityData?.section1?.vImageTitleText2} alt={QualityData?.section1?.vImageAltText2} /></li>
                                                : ''
                                        }
                                    </div>
                                    {/* <div className="list right">
                                        {
                                            QualityData?.section1?.vImage2 ?
                                                <li><img loading="lazy" className='quality-img' src={QualityData?.section1?.vImage2} title={QualityData?.section1?.vImageTitleText3} alt={QualityData?.section1?.vImageAltText3} /></li>
                                                : ''
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white quality-product-section content-section pdb-0">
                    <div className="container container-big">
                        <div className="quality-product">
                            <div className="quality-list left">
                                <p>{QualityData?.section1?.vParagraph1}</p>
                                <ul className="list01">
                                    {
                                        Object.keys(QualityData?.section1?.contentList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(QualityData?.section1?.contentList).map((curEle, i) => {
                                                        const list = QualityData?.section1?.contentList[curEle];
                                                        return (
                                                            <li key={i}>{list}</li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                </ul>
                                <p>{QualityData?.section1?.vParagraph2}</p>
                            </div>
                            <div className="quality-list right">
                                <h3 className="tit">Products</h3>
                                <ul className="quality-product-list">
                                {
                                        product.map((curEle, index) => {
                                            const filterSubProduct=product.filter((curData,index)=>{
                                                return curData.iParentProdId==curEle.iProductId
                                            })
                                            return  curEle.iParentProdId==""?<li className="product-item" key={index}>
                                                        <a href='javascript:void(0)' onClick={()=>productDetailPage(curEle.vProductName+'-'+returnCategoryName(curEle.iCategoryId),filterSubProduct.length)} className="product-link">
                                                            <div className="product-inn">
                                                                <div className="product-img">
                                                                    <img loading="lazy" src={UploadUrl + 'product/' + curEle.vProductImage} alt={curEle.vImageAltText} title={curEle.vImageTitleText} />
                                                                </div>
                                                                <h4 className="product-name">
                                                                    <span>{curEle.vProductName} <br />{returnCategoryName(curEle.iCategoryId)}</span>
                                                                </h4>
                                                            </div>
                                                        </a>
                                                    </li>:""
                                        })
                                    }
                                </ul>
                                <div className="view-all-product">
                                    <a className="btn-outline hvr-sweep-to-top" href="products">View All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white quality-content-section content-section pdb-0">
                    <div className="container container-big">
                        <div className="quality-content">
                            <div className="quality-content-list left">
                                {
                                    QualityData?.section2?.vImage1 ?
                                        <img loading="lazy" className="quality-img" src={QualityData?.section2?.vImage1} alt={QualityData?.section2?.vImageAltText1} title={QualityData?.section2?.vImageTitleText1} />
                                        : ''
                                }
                            </div>
                            <div className="quality-content-list right">
                                <h4 className="tit2">{QualityData?.section2?.vTitle1}</h4>
                                <ul className="list01 pdb-0">
                                    {
                                        Object.keys(QualityData?.section2?.QOList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(QualityData?.section2?.QOList).map((curEle, i) => {
                                                        const list = QualityData?.section2?.QOList[curEle];
                                                        return (
                                                            <li key={i}>{list}</li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white quality-content-section content-section">
                    <div className="container container-big">
                        <div className="quality-content right-image">
                            <div className="quality-content-list left">
                                {
                                    QualityData?.section2?.vImage2 ?
                                        <img loading="lazy" className="quality-img" src={QualityData?.section2?.vImage2} alt={QualityData?.section2?.vImageAltText2} title={QualityData?.section2?.vImageTitleText2} />
                                        : ''
                                }
                            </div>
                            <div className="quality-content-list right">
                                <h4 className="tit2">{QualityData?.section2?.vTitle2}</h4>
                                <ul className="list01 pdb-0">
                                    {
                                        Object.keys(QualityData?.section2?.QPList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(QualityData?.section2?.QPList).map((curEle, i) => {
                                                        const list = QualityData?.section2?.QPList[curEle];
                                                        return (
                                                            <li key={i}>{list}</li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white quality-services-section">
                    <div className="container container-big">
                        <div className="quality-services">
                            <div className="quality-services-list left">
                                <h5 className="tit">{QualityData?.section3?.vTitle}</h5>
                            </div>
                            <div className="quality-services-list right">
                                <ul className="quality-list">
                                    {
                                        Object.keys(QualityData?.section3?.ProductServiceList).length > 0 ?
                                            <>
                                                {
                                                    Object.keys(QualityData?.section3?.ProductServiceList).map((curEle, i) => {
                                                        const list = QualityData?.section3?.ProductServiceList[curEle];
                                                        return (
                                                            <li key={i}>
                                                                {
                                                                    list?.vImage ?
                                                                        <img loading="lazy" src={list?.vImage} alt={list?.vImageAltText} title={list?.vImageTitleText} />
                                                                        : ''
                                                                }
                                                                {list?.vListName}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </>
                                            : ''
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white quality-certificate-section pdb-0">
                    {/* <video className="certificate-video" autoPlay loop muted preload="metadata" poster={adboutData?.section1?.videoThumbnailList && adboutData?.section1?.videoThumbnailList.length>0?adboutData?.section1?.videoThumbnailList[2]:""}>
                        <source id="mp4" src={adboutData?.section1?.videoList[2]} type="video/mp4" />
                    </video> */}
                    <div className="container container-big">
                        <div className="quality-certificate">
                            <div className="quality-certificate-list left"><img loading="lazy" className="iso-img" src={`${process.env.PUBLIC_URL}/images/iso.png`} title="ISO Certificate" alt="ISO Certificate" /></div>
                            <div className="quality-certificate-list middle"><img loading="lazy" className="certificate-img" src={`${process.env.PUBLIC_URL}/images/certificate.png`} title="Certificate ISO 9001:2015" alt="Certificate ISO 9001:2015" /></div>
                            <div className="quality-certificate-list right">
                                <div>
                                    <h5 className="tit"><span className="sub-tit">We are Certified with</span>ISO 9001:2015<span className="sub-tit">TUV SUD, South Asia</span></h5>
                                    <a className="btn-line btn-blue btn-icon" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalViewISO"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="0.5 0 24 24"><circle cx="12.5" cy="12" r="1.5" fill="none" /><path fill="none" d="M12.5 6.333c-4.322 0-7.189 4.254-8.022 5.665.836 1.411 3.716 5.668 8.022 5.668 4.305 0 7.185-4.256 8.021-5.667-.836-1.411-3.716-5.666-8.021-5.666zm0 9.167C10.57 15.5 9 13.93 9 12s1.57-3.5 3.5-3.5S16 10.07 16 12s-1.57 3.5-3.5 3.5z" /><path fill="currentColor" d="M22.562 11.553c-.147-.295-3.684-7.219-10.062-7.219-6.378 0-9.914 6.924-10.061 7.219a1 1 0 0 0 0 .895c.147.295 3.683 7.22 10.061 7.22s9.914-6.925 10.062-7.22c.14-.282.14-.614 0-.895zM12.5 17.667c-4.306 0-7.186-4.258-8.022-5.668.833-1.411 3.7-5.665 8.022-5.665 4.305 0 7.185 4.255 8.021 5.667-.836 1.41-3.716 5.666-8.021 5.666z" /><path fill="currentColor" d="M12.5 8.5C10.57 8.5 9 10.07 9 12s1.57 3.5 3.5 3.5S16 13.93 16 12s-1.57-3.5-3.5-3.5zm0 5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z" /></svg>View ISO 9001:2015</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="section-white about-infrastructure-section pdb-0">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">{QualityData?.section4?.vTitle}</h3>
                            <div className="desc">{QualityData?.section4?.vText}</div>
                        </div>
                        <div className='AboutInfrastructureSlider'>
                            <AboutInfraStructureComp/>
                        </div>
                    </div>
                </div> */}
                
                <div className="section-white home-bottom-section">
                    <div className="container container-big">
                        <QualityDunsCommon dunAccess={dunAccess}/>
                    </div>
                </div>
            </div>
            <div className="modal view-iso-certificate-modal fade" id="ModalViewISO" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1" aria-labelledby="ModalViewISOLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ModalViewISOLabel">ISO 9001:2015</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather icon feather-x"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg></button>
                        </div>
                        <div className="modal-body">
                            <div className="pd-20">
                                <img loading="lazy" className="iso-certificate" src={`${process.env.PUBLIC_URL}/images/iso-9001-2015-certificate.jpg`} alt="ISO 9001:2015 Certificate" title="ISO 9001:2015 Certificate" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CommonLayout>
    )
}

export default Quality