import React, { useEffect, useState } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import data from './json/about.json';
import DownloadBrochureModal from './Common/DownloadBrochureModal';
import AppendScript from './AppendScript';
import DunsCommon from './Common/DunsCommon';
import HomeIndustriesComp from './Common/HomeIndustries';
import AboutInfraStructureComp from './Common/AboutInfraStructureComp';

function About() {

    useEffect(() => {
        scrollBottomToTop();
    }, [])

    const [isShow, setIsShow] = useState(false);

    const [dunAccess, setDunAccess] = useState("");
    const handleGetData = (data) => {
        setDunAccess(data);
    }

    return (
        <CommonLayout handleGetData={handleGetData}>
            <div className="content-main sub-page">
                <div className="section-white about-top-section">
                    <img loading="lazy" className="about-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container">
                        <div className="section-title2">
                            <h2 className="title"><span className="sub-title">{data?.section1?.vTitle}</span>{data?.section1?.vHeading}</h2>
                            <div className="desc">{data?.section1?.vHeadParagraph}</div>
                        </div>
                    </div>
                    <div className="about-video-section-main">
                        <div className="container container-big">
                            <div className="about-video-section">
                                <div className="about-video-list">
                                    {/* <video className="about-video" autoPlay loop muted preload="metadata" poster={data?.section1?.videoThumbnailList && data?.section1?.videoThumbnailList.length>0?data?.section1?.videoThumbnailList[1]:""} id="myVideo">
                                        <source id="mp4" src={data?.section1?.videoList[1]} type="video/mp4" />
                                    </video> */}
                                    {/* <img className="about-video" loading="lazy" src={data?.section1?.videoList[1]} title={data?.section1?.ImageTitleText?data?.section1?.ImageTitleText:''} alt={data?.section1?.ImageTitleText?data?.section1?.ImageTitleText:''} /> */}
                                    <img className="about-video" loading="lazy" src={`${data?.section1?.sect1_box1_image_upload}`} alt={data?.section1?.Sect1imageAltText} title={data?.section1?.Sect1imageTitleText} />
                                </div>
                                <div className="about-video-list right-video">
                                    <div className="small-video">
                                        <video className="about-video" autoPlay loop muted preload="metadata" poster={data?.section1?.videoThumbnailList && data?.section1?.videoThumbnailList.length>0?data?.section1?.videoThumbnailList[1]:""}>
                                            <source id="mp4" src={data?.section1?.videoList[1]} type="video/mp4" />
                                        </video>
                                    </div>
                                    <div className="small-video last">
                                       {/*  <video className="about-video" autoPlay loop muted preload="metadata" poster={data?.section1?.videoThumbnailList && data?.section1?.videoThumbnailList.length>0?data?.section1?.videoThumbnailList[2]:""}>
                                            <source id="mp4" src={data?.section1?.videoList[2]} type="video/mp4" />
                                        </video> */}
                                        <ul className="about-certified">
                                            <li className="name">We are Certified with</li>
                                            <li><img loading="lazy" src={data?.section1?.imageList[1]['vImage']} title={data?.section1?.imageList[1]['ImageTitleText']} alt={data?.section1?.imageList[1]['imageAltText']} /></li>
                                            <li><img loading="lazy" src={data?.section1?.imageList[2]['vImage']} title={data?.section1?.imageList[2]['ImageTitleText']} alt={data?.section1?.imageList[2]['imageAltText']} /></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="about-content">
                            <div className="about-list left">
                                <div dangerouslySetInnerHTML={{ __html: data?.section1?.vParagraph1 }}></div>
                            </div>
                            <div className="about-list right">
                                <div dangerouslySetInnerHTML={{ __html: data?.section1?.vParagraph2 }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="section-white about-company-overview-section">
                    <div className="container container-big">
                        <div className="company-overview">
                            <div className="company-list left">
                                <h3 className="tit">{data?.section2?.vTitle}</h3>
                            </div>
                            <div className="company-list right">
                                <ul className="text-big-list">
                                    {
                                        Object.keys(data?.section2?.OverviewList).map((curEle, index) => {
                                            const aboutSpan = data?.section2?.OverviewList[curEle];
                                            return <li className="text-big-list-item" data-key={index} key={index}>
                                                <Marquee speed={300} loop={0}>
                                                    {Array.from({ length: 50 }, (_, i) => <div key={i} className="marqueediv" dangerouslySetInnerHTML={{ __html: aboutSpan }}></div>)}
                                                </Marquee>
                                            </li>
                                        })
                                    }
                                </ul>
                                <ul className="text-small-list">
                                    {
                                        Object.keys(data?.section2?.OverviewList).map((curEle, index) => {
                                            const aboutSpan = data?.section2?.OverviewList[curEle];
                                            return <li className="text-small-list-item" data-key={index} key={index} dangerouslySetInnerHTML={{ __html: aboutSpan }}></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="section-white about-company-overview-section">
                    <div className="container container-big">
                        <div className="company-overview">
                            <div className="company-list left">
                                <h3 className="tit">Company Overview</h3>
                            </div>
                            <div className="company-list right">
                                <ul className="text-big-list">
                                    <li className="text-big-list-item"><div className="marqueediv"><span className="grey-text">We</span> <span className="blue-text">established in 1992</span> <span className="grey-text">with a</span> small manufacturing facility.</div></li>
                                    <li className="text-big-list-item"><div className="marqueediv"><span className="blue-text">Directors</span> <span className="grey-text">with vast</span> experience <span className="grey-text">in</span> field of Mechanical</div></li>
                                    <li className="text-big-list-item active"><div className="marqueediv"><span className="blue-text">OEM</span> <span className="grey-text">of</span> Precision Brass Turned Components</div></li>
                                    <li className="text-big-list-item"><div className="marqueediv"><span className="blue-text">ISO 9001 : 2015 CERTIFIED</span> <span className="grey-text">for</span> Quality Standards</div></li>
                                    <li className="text-big-list-item"><div className="marqueediv">Material Speciality <span className="grey-text">in</span> <span className="blue-text">Brass</span><span className="grey-text">,</span> <span className="blue-text">Steel</span><span className="grey-text">,</span> <span className="blue-text">Copper</span><span className="grey-text">,</span> <span className="blue-text">Sheet Metal</span><span className="grey-text">,</span> <span className="blue-text">Aluminum</span></div></li>
                                    <li className="text-big-list-item"><div className="marqueediv"><span className="blue-text">Skilled</span><span className="grey-text">,</span> <span className="blue-text">Trained</span> <span className="grey-text">&</span> <span className="blue-text">Experienced</span> Manpower <span className="grey-text">of</span> <span className="blue-text">400+</span></div></li>
                                    <li className="text-big-list-item"><div className="marqueediv">Major Exports <span className="grey-text">in the</span> <span className="blue-text">UK</span><span className="grey-text">,</span> <span className="blue-text">Europe</span> <span className="grey-text">&</span> <span className="blue-text">US</span></div></li>
                                </ul>
                                <ul className="text-small-list">
                                    <li className="text-small-list-item"><span className="grey-text">We</span> <span className="blue-text">established in 1992</span> <span className="grey-text">with a</span> small manufacturing facility.</li>
                                    <li className="text-small-list-item"><span className="blue-text">Directors</span> <span className="grey-text">with vast</span> experience <span className="grey-text">in</span> field of Mechanical</li>
                                    <li className="text-small-list-item active"><span className="blue-text">OEM</span> <span className="grey-text">of</span> Precision Brass Turned Components</li>
                                    <li className="text-small-list-item"><span className="blue-text">ISO 9001 : 2015 CERTIFIED</span> <span className="grey-text">for</span> Quality Standards</li>
                                    <li className="text-small-list-item">Material Speciality <span className="grey-text">in</span> <span className="blue-text">Brass</span><span className="grey-text">,</span> <span className="blue-text">Steel</span><span className="grey-text">,</span> <span className="blue-text">Copper</span><span className="grey-text">,</span> <span className="blue-text">Sheet Metal</span><span className="grey-text">,</span> <span className="blue-text">Aluminum</span></li>
                                    <li className="text-small-list-item"><span className="blue-text">Skilled</span><span className="grey-text">,</span> <span className="blue-text">Trained</span> <span className="grey-text">&</span> <span className="blue-text">Experienced</span> Manpower <span className="grey-text">of</span> <span className="blue-text">400+</span></li>
                                    <li className="text-small-list-item">Major Exports <span className="grey-text">in the</span> <span className="blue-text">UK</span><span className="grey-text">,</span> <span className="blue-text">Europe</span> <span className="grey-text">&</span> <span className="blue-text">US</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="section-white about-timeline-section">
                    <div className="container container-big">
                        <h3 className="tit">Company Overview</h3>
                    </div>
                    <div className="timeline-section">
                        <div className="container container-big">
                            <div className="about-timeline-slider">
                                <div className="timeline-item-list">
                                    <div className="timeline-item first-timeline">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">1992</h4>
                                            <div className="desc">Founded by Snehal Gohel in Jamnagar, Gujarat as a Micro Brass component manufacturer</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        </>
                                    </div>
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">1998</h4>
                                            <div className="desc">With investments, we initiated an expansion by procuring the latest machinery and technologies</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        </>
                                    </div>
                                </div>
                                <div className="timeline-item-list">
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">2005</h4>
                                            <div className="desc">Expansion in product portfolio with various ferrous and non-ferrous metals</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        </>
                                    </div>
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">2013</h4>
                                            <div className="desc">Joint venture with a German company for the European market to share technologies, development, and market shares</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        
                                        </>
                                    </div>
                                </div>
                                <div className="timeline-item-list">
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">2015</h4>
                                            <div className="desc">Started in-house Assembly & sub-assemblies, catering to customer needs</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        
                                        </>
                                    </div>
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">2019</h4>
                                            <div className="desc">Expansion on a large scale by procuring a new manufacturing land area of 79,896.61 sq.m.</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        
                                        </>
                                    </div>
                                </div>
                                <div className="timeline-item-list">
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">2022</h4>
                                            <div className="desc">Providing logistical support to customers as we establish a warehouse facility in the United States.</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        
                                        </>
                                    </div>
                                    <div className="timeline-item">
                                        <>
                                        <div className="timeline-bg">
                                            <h4 className="year-tit">2023</h4>
                                            <div className="desc">To be Continued...</div>
                                        </div>
                                        <div className="timeline-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                                        <img className="timeline-arrow-icon" loading="lazy" src={`${process.env.PUBLIC_URL}/images/arrow-top-blue.svg`} />
                                        
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white about-mission-section">
                    <div className="container container-big">
                        <div className="about-mission-inn">
                            <div className="about-mission-list left">
                                <div className="icon-image">
                                    <img loading="lazy" src={data.section3[1]['vImage']} alt={data.section3[1]['AltText']} title={data.section3[1]['TitleText']} />
                                </div>
                                <div className="right-content">
                                    <h4 className="tit">{data.section3[1]['vTitle']}</h4>
                                    <div className="desc" dangerouslySetInnerHTML={{ __html: data.section3[1]['vParagraph'] }}></div>
                                </div>
                            </div>
                            <div className="about-mission-list right">
                                <div className="icon-image">
                                    <img loading="lazy" src={data.section3[2]['vImage']} alt={data.section3[2]['AltText']} title={data.section3[2]['TitleText']} />
                                </div>
                                <div className="right-content">
                                    <h4 className="tit">{data.section3[2]['vTitle']}</h4>
                                    <div className="desc" dangerouslySetInnerHTML={{ __html: data.section3[2]['vParagraph'] }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-white about-founder-section">
                    <img loading="lazy" className="founder-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-founder-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container">
                        <div className="about-founder">
                            <div className="founder-list left">
                                <h3 id="FounderVision" className="founder-vision" dangerouslySetInnerHTML={{ __html: data?.section4?.vTitle }}></h3>
                                <img loading="lazy" className="founder-image" src={data?.section4?.vImage} alt={data?.section4?.vImageAltText} title={data?.section4?.vImageTitleText} />
                            </div>
                            <div className="founder-list right">
                                <h3 className="tit">{data.section4.vName}<span className="sub-tit">{data.section4.vDesignation}</span></h3>
                                <div className="founder-message" dangerouslySetInnerHTML={{ __html: data.section4.vParagraph }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="section-white about-infrastructure-section pdb-0">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">{data?.section5?.vTitle}</h3>
                            <div className="desc">{data?.section5?.vText}</div>
                        </div>
                        <div className="AboutInfrastructureSlider">
                            <AboutInfraStructureComp />
                        </div>
                    </div>
                </div> */}
                <div className="section-white home-industries-section pdb-0">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">{data.section6.vTitle}</h3>
                            <div className="desc">{data.section6.vText}</div>
                        </div>
                        <div className="HomeIndustriesSlider">
                            <HomeIndustriesComp />
                        </div>
                    </div>
                </div>

                <div className="section-white home-bottom-section about-certificate3-section">
                    <div className="container container-big">
                        <DunsCommon dunAccess={dunAccess} />
                    </div>
                </div>
            </div>

            <DownloadBrochureModal show={isShow} />
            <AppendScript />
        </CommonLayout>
    )
}

export default About