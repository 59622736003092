import React, { useEffect, useState } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import DownloadBrochureModal from './Common/DownloadBrochureModal';
import axios from 'axios';
import { MailUrl } from '../Services/CommonUrl';
var validator = require('validator');

function Enquiry() {
    useEffect(() => {
        scrollBottomToTop();
    }, [])


    const [form,setForm]=useState({
        vName:"",
        vCompanyName:"",
        vEmail:"",
        vPhoneNumber:"",
        vAddress:"",
        vCountry:"",
        vState:"",
        vCity:"",
        ProductInterested:"",
        vUploadDrawings:"",
        vDescribeYourRequirement:"",
        vUploadDrawingsName:""
    });

    const [errorMessageArr,setErrorMessageArr]=useState({
        vName:"",
        // vCompanyName:"",
        vEmail:"",
        vPhoneNumber:"",
        vAddress:"",
        vCountry:"",
        vState:"",
        vCity:"",
        ProductInterested:"",
        vUploadDrawings:"",
        vDescribeYourRequirement:"",
    })

    const ErroLable={
        vName:"This field is required!",
        vCompanyName:"This field is required!",
        vEmail:"This field is required!",
        vPhoneNumber:"This field is required!",
        vAddress:"This field is required!",
        vCountry:"This field is required!",
        vState:"This field is required!",
        vCity:"This field is required!",
        ProductInterested:"This field is required!",
        vUploadDrawings:"This field is required!",
        vDescribeYourRequirement:"This field is required!",
    }

    useEffect(()=>{
        Object.keys(form).map((curEle,index)=>{
            if(form[curEle]==""){
                if(curEle!="vUploadDrawings" && curEle!="vUploadDrawingsName"){
                    setErrorMessageArr(prevState=>{
                        return{
                            ...prevState,
                            [curEle]:ErroLable[curEle]
                        }
                    });
                }
            }else{
                if(curEle=="vEmail"){
                    if(!validator.isEmail(form.vEmail)){
                        setErrorMessageArr(prevState=>{
                            return{
                                ...prevState,
                                [curEle]:"Email is not valid!"
                            }
                        });
                    }else{
                        setErrorMessageArr(prevState=>{
                            return{
                                ...prevState,
                                [curEle]:""
                            }
                        });
                    }
                }else{
                    setErrorMessageArr(prevState=>{
                        return{
                            ...prevState,
                            [curEle]:""
                        }
                    });
                }
            }
        })
    },[form])    

    const handleChange=(e,file="")=>{
        if(file==""){
            setForm(prevState=>{
                return{
                    ...prevState,
                    [e.target.name]:e.target.value
                }
            })
        }else{
            const file = e.target.files[0];
            if (file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64String = reader.result.split(',')[1];
                    const isFileExt = (file) => {
                        const splitArr=file?.split("/")
                        return splitArr[1];
                    };

                    if(e.target.name=="vUploadDrawings" && file.size <= Number(5 * 1024 * 1024) && (isFileExt(file.type)=="pdf" || isFileExt(file.type)=="dwg")){
                        setForm(prevState=>{
                            return{
                                ...prevState,
                                [e.target.name]:base64String,
                                vUploadDrawingsName:file.name
                            }
                        });
                    }else{
                        if(e.target.name=="vUploadDrawings"){
                            if(file.size > 5 * 1024 * 1024){
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vUploadDrawings']:'File max upload limit is 2MB.'
                                    }
                                })
                            }else if(isFileExt(file.type)!="jpg" && isFileExt(file.type)!="pdf" || isFileExt(file.type)!="dwg"){
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vUploadDrawings']:'File format not valid.'
                                    }
                                })
                            }else{
                                setErrorMessageArr(prevState=>{
                                    return{
                                        ...prevState,
                                        ['vUploadDrawings']:''
                                    }
                                })
                            }
                            return false;
                        }
                    }
                };

                reader.readAsDataURL(file);
            }
        }
    }


    const [isSubmitErr,setIsSubmitErr]=useState(false);
    const [isMailSend,setisMailSend]=useState(false);
    const [errorSendMail,setErrorSendMail]=useState("");
    function sendEmail() {
        const filterReq=Object.keys(errorMessageArr).filter((curEle,index)=>{
            return errorMessageArr[curEle]!=""
        })

        const filterTotalFil=Object.keys(form).filter((curEle,index)=>{
            return errorMessageArr[curEle]==""
        })

        if(filterReq.length==0 && filterTotalFil.length){
            axios.post(MailUrl, {
                action:"enquiryMail",
                data:form
              })
              .then(function (response) {
                if(response.data.status==200){
                    setForm({
                        vName:"",
                        vCompanyName:"",
                        vEmail:"",
                        vPhoneNumber:"",
                        vAddress:"",
                        vCountry:"",
                        vState:"",
                        vCity:"",
                        ProductInterested:"",
                        vUploadDrawings:"",
                        vDescribeYourRequirement:"",
                        vUploadDrawingsName:""
                    });
                    setisMailSend(true);
                    setIsSubmitErr(false);
                    setErrorSendMail("");
                }else{
                    if(response?.data?.status==412){
                        setErrorSendMail(response?.data?.msg);
                    }
                    setisMailSend(false);
                }
              })
              .catch(function (error) {
                // console.log(error)
              });
        }else{
            setIsSubmitErr(true);
        }
    }

    return (
        <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white enquiry-now-section pdt-0 pdb-0">
                    <div className="enquiry-list left">
                        <h4 className="enquiry-tit">Enquiry</h4>
                        <div className="field-row">
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Your Name<span>*</span></label>
                                    <input className="form-control" type="text" id="vName" name="vName" onChange={handleChange} value={form.vName} />
                                    {
                                        isSubmitErr && errorMessageArr.vName!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vName}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Company Name</label>
                                    <input className="form-control" type="text" id="vCompanyName" name="vCompanyName" onChange={handleChange} value={form.vCompanyName}/>
                                    {/* {
                                        isSubmitErr && errorMessageArr.vCompanyName!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vCompanyName}</div>
                                        :""
                                    } */}
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Your Email<span>*</span></label>
                                    <input className="form-control" type="text" id="vEmail" name="vEmail" onChange={handleChange} value={form.vEmail}/>
                                    {
                                        isSubmitErr && errorMessageArr.vEmail!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vEmail}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Phone Number<span>*</span></label>
                                    <input className="form-control" type="text" id="vPhoneNumber" name="vPhoneNumber" onChange={handleChange} value={form.vPhoneNumber}/>
                                    {
                                        isSubmitErr && errorMessageArr.vPhoneNumber!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vPhoneNumber}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Address<span>*</span></label>
                                    <input className="form-control" type="text" id="vAddress" name="vAddress" onChange={handleChange} value={form.vAddress}/>
                                    {
                                        isSubmitErr && errorMessageArr.vAddress!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vAddress}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Country<span>*</span></label>
                                    <input className="form-control" type="text" id="vCountry" name="vCountry" onChange={handleChange} value={form.vCountry} />
                                    {
                                        isSubmitErr && errorMessageArr.vCountry!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vCountry}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">State<span>*</span></label>
                                    <input className="form-control" type="text" id="vState" name="vState" onChange={handleChange} value={form.vState} />
                                    {
                                        isSubmitErr && errorMessageArr.vState!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vState}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">City<span>*</span></label>
                                    <input className="form-control" type="text" id="vCity" name="vCity" onChange={handleChange} value={form.vCity}/>
                                    {
                                        isSubmitErr && errorMessageArr.vCity!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vCity}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Product interested in<span>*</span></label>
                                    <select id="ProductInterested" name="ProductInterested" className="form-control select2-single" onChange={handleChange}>
                                        <option value="">Product interested in</option>
                                        <option value="Automobile Parts" selected={form.ProductInterested=="Automobile Parts"}>Automobile Parts</option>
                                        <option value="Aluminium Components" selected={form.ProductInterested=="Aluminium Components"}>Aluminium Components</option>
                                        <option value="Brass Machined Components" selected={form.ProductInterested=="Brass Machined Components"}>Brass Machined Components</option>
                                        <option value="Brass Electrical Components" selected={form.ProductInterested=="Brass Electrical Components"}>Brass Electrical Components</option>
                                        <option value="Brass CNC Parts" selected={form.ProductInterested=="Brass CNC Parts"}>Brass CNC Parts</option>
                                        <option value="Brass Sheet Metal/ Stamping Parts" selected={form.ProductInterested=="Brass Sheet Metal/ Stamping Parts"}>Brass Sheet Metal/ Stamping Parts</option>
                                        <option value="Brass Precision Miniature Parts" selected={form.ProductInterested=="Brass Precision Miniature Parts"}>Brass Precision Miniature Parts</option>
                                        <option value="Brass Knurling Inserts" selected={form.ProductInterested=="Brass Knurling Inserts"}>Brass Knurling Inserts</option>
                                        <option value="Fasteners" selected={form.ProductInterested=="Fasteners"}>Fasteners</option>
                                        <option value="Brass Contact Pin and Sockets" selected={form.ProductInterested=="Brass Contact Pin and Sockets"}>Brass Contact Pin and Sockets</option>
                                        <option value="Brass Turned Components" selected={form.ProductInterested=="Brass Turned Components"}>Brass Turned Components</option>
                                        <option value="Brass Terminal Bars" selected={form.ProductInterested=="Brass Terminal Bars"}>Brass Terminal Bars</option>
                                        <option value="Copper Parts" selected={form.ProductInterested=="Copper Parts"}>Copper Parts</option>
                                        <option value="Cam Lock Connectors" selected={form.ProductInterested=="Cam Lock Connectors"}>Cam Lock Connectors</option>
                                    </select>
                                    {
                                        isSubmitErr && errorMessageArr.ProductInterested!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.ProductInterested}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2">
                                <div className="form-group">
                                    <label className="control-label">Upload Drawings</label>
                                    <input className="form-control" type="file" id="vUploadDrawings" name="vUploadDrawings" onChange={(e)=>handleChange(e,1)}/>
                                    {
                                        isSubmitErr && errorMessageArr.vUploadDrawings!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vUploadDrawings}</div>
                                        :""
                                    }
                                </div>
                            </div>
                            <div className="form-field2 full">
                                <div className="form-group">
                                    <label className="control-label">Describe Your Requirement<span>*</span></label>
                                    <textarea name="vDescribeYourRequirement" id="vDescribeYourRequirement" className="form-control" onChange={handleChange}>{form.vDescribeYourRequirement}</textarea>
                                    {
                                        isSubmitErr && errorMessageArr.vDescribeYourRequirement!=""?
                                        <div className="invalid-feedback2">{errorMessageArr.vDescribeYourRequirement}</div>
                                        :""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-submit">
                            <button className="btn" type="submit" onClick={sendEmail}>Submit</button>
                        </div>
                        {
                            isMailSend || errorSendMail!=""?
                            <>
                            {
                                isMailSend?
                                <div className="alert alert-success">Thank you for your message. It has been sent.</div>
                                :''
                            }
                            {
                                errorSendMail!=""?
                                <div className="alert alert-danger">{errorSendMail}</div>
                                :""
                            }
                            </>:''
                        }
                    </div>
                    <div className="enquiry-list right">
                        <div className="section-tit">
                            <h4 className="tit">Download Our Brochure</h4>
                            <div className="desc">Know more about us, our entire product range, and how we are providing solutions for your custom requirement</div>
                            <a className="btn btn-icon" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalDownloadBrochure"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="-3 -2.5 24 24"><path fill="currentColor" d="M15.315 19.5H2.684a3.137 3.137 0 0 1-2.232-.925 3.139 3.139 0 0 1-.925-2.233v-2.104a1.052 1.052 0 1 1 2.105 0v2.104c0 .276.113.548.309.744.196.196.467.309.744.309h12.631a1.056 1.056 0 0 0 1.053-1.053v-2.104a1.052 1.052 0 1 1 2.106 0v2.104a3.14 3.14 0 0 1-.925 2.233 3.146 3.146 0 0 1-2.235.925zM9 14.237h-.003c-.141 0-.276-.029-.398-.08a1.027 1.027 0 0 1-.343-.229L2.993 8.666a1.053 1.053 0 0 1 1.488-1.489l3.466 3.467V.553a1.052 1.052 0 1 1 2.106 0v10.091l3.466-3.467a1.053 1.053 0 0 1 1.489 1.489l-5.264 5.263a1.048 1.048 0 0 1-.741.309L9 14.237z"></path></svg>Download Brochure</a>
                        </div>
                        <div className="enquiry-image"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/enquiry.png`} alt="Enquiry Shiv Om Brass Industries" title="Enquiry Shiv Om Brass Industries" /></div>
                    </div>
                </div>
            </div>

            <DownloadBrochureModal />
        </CommonLayout>
    )
}

export default Enquiry