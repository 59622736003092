import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About';
import Quality from './Components/Quality';
import Product from './Components/Product';
import ProductDetail from './Components/ProductDetail';
import Blog from './Components/Blog'
import BlogDetail from './Components/BlogDetail';
import Career from './Components/Career';
import Contact from './Components/Contact';
import TermsConditions from './Components/TermsConditions';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Covid from './Components/Covid';
import Enquiry from './Components/Enquiry';
import SubProduct from './Components/SubProduct';

function Routing() {
    return (
        //<Router basename={'/shivom/'}>
        <Router basename="/">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/company" element={<About />} />
                <Route path="/quality" element={<Quality />} />
                <Route path="/products" element={<Product />} />
                <Route path="/sub-product/:id" element={<SubProduct />} />
                <Route path="/product/:id" element={<ProductDetail />} />
                <Route path="/news-events" element={<Blog />} />
                <Route path="/news/:id" element={<BlogDetail />} />
                <Route path="/career" element={<Career />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/terms-and-conditions" element={<TermsConditions />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/covid-19-updates" element={<Covid />} />
                <Route path="/enquiry" element={<Enquiry />} />
                {/* <Route path="*" element={<Home />} /> */}
            </Routes>
        </Router>
    )
}

export default Routing