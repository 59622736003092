import React, { useEffect, useState } from 'react'
import CommonLayout from './Common/CommonLayout'
import Slider from 'react-slick';
import { padNum, scrollBottomToTop } from './Common/Functions';
import data from './json/home.json'
import testimonialdata from './json/testimonial.json';
import { Link, useNavigate } from 'react-router-dom';
import DownloadBrochureModal from './Common/DownloadBrochureModal';
import blogData from './json/blog.json';
import AppendScript from './AppendScript';
import HomeDunsCommon from './Common/HomeDunsCommon';
import HomeIndustriesComp from './Common/HomeIndustries';
import ProductSliderCommon from './Common/ProductSliderCommon';
import _ from 'lodash';

function Home(props) {
    const navigate = useNavigate();

    const homeSlider = {
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite:true,
        swipeToSlide:true,
        dots: true,
        fade: true,
        cssEase: 'linear',
        //asNavFor: '.slider-nav',
        pauseOnHover:false,
        autoplay: true,
        autoplaySpeed: 6000
    };

    const HomeAwardsSlider = {
        // speed: 5000,
        // autoplay: true,
        // autoplaySpeed: 0,
        // centerMode: true,
        // pauseOnHover:false,
        // cssEase: 'linear',
        // slidesToShow: 1,
        // slidesToScroll: 1,
        // variableWidth: true,
        // infinite: true,
        // initialSlide: 1,
        // arrows: false,
        // buttons: false,
        // adaptiveHeight: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        buttons: false,
        variableWidth: true,
        adaptiveHeight: true,
        autoplay: true,
        pauseOnHover:false, 
    }

    useEffect(() => {
        scrollBottomToTop();
    }, [])

    function getExtension(filename) {
        var parts = filename.split('.');
        return parts[parts.length - 1];
    }

    function isVideo(filename) {
        var ext = getExtension(filename);
        switch (ext.toLowerCase()) {
          case 'm4v':
          case 'avi':
          case 'mpg':
          case 'mp4':
            // etc
            return true;
        }
        return false;
    }
    
    const n1 = 2;
    const FeaturedBlogs=blogData?.blogList?.filter((curEle,index)=>{
        return curEle?.vCategory.includes("Featured");
    })
    const result2 = FeaturedBlogs.slice(-n1);

    const n = 3;
    const LatestBlogs=blogData?.blogList?.filter((curEle,index)=>{
        return !curEle?.vCategory.includes("Featured");
    })
    const result = LatestBlogs.slice(-n);


    const blogDetailPage = (name) =>{
        const b = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        navigate(`/news/${b}`);
        // blogData?.blogList?.map((curEle,index)=>{
        //     if(curEle.id==id){
        //     }
        // })
    };

    

    const [dunAccess,setDunAccess]=useState("");
    const handleGetData=(data)=>{
        setDunAccess(data);
    }

    return (
        <CommonLayout handleGetData={handleGetData}>
            <div className="home-slider">
                <Slider {...homeSlider}>
                    {
                        data?.section1.map((curEle, index) => {
                            const SliderNum = padNum(index + 1);
                            return <div className={`hero-slide slide${SliderNum}`} key={index}>
                                <div className="slider-video-main">
                                    {
                                        isVideo(''+curEle.vFile)?
                                        <video className="slider-video" autoPlay loop muted preload="metadata" poster="">
                                            <source id="mp4" className="local-video" src={curEle.vFile} type="video/mp4" />
                                        </video>
                                        :
                                        <img loading="lazy" className='slider-video' src={curEle.vFile} alt={curEle.vFileAltText} title={curEle.vFileTitleText} />
                                    }
                                </div>
                                <div className="slider-cap">
                                    <div className="center">
                                        <div className="slider-cap-inn">
                                            <div className="cap-left">
                                                <h3 className="sub-tit">{curEle.vHeading}</h3>
                                                {
                                                    curEle.vSubHeading ? <h3 className="tit">{curEle.vSubHeading}</h3> : ''
                                                }
                                                {
                                                    curEle.vParagraph ? <div className="left-desc">{curEle.vParagraph}</div> : ''
                                                }
                                                {
                                                    curEle.vButtonLabelName != "" ?
                                                        <div className="slider-btn">
                                                            <Link className="btn btn-white" to={curEle.vButtonLabelLink}>
                                                                {curEle.vButtonLabelName}
                                                            </Link>
                                                            <a className="btn-outline s-download-brochure btn-icon btn-white hvr-sweep-to-top" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalDownloadBrochure"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 21.5H6a2.982 2.982 0 0 1-2.121-.879A2.978 2.978 0 0 1 3 18.5v-2a1 1 0 1 1 2 0v2c0 .264.107.521.293.707.186.186.444.293.707.293h12a1.002 1.002 0 0 0 1-1v-2a1 1 0 1 1 2 0v2c0 .801-.313 1.555-.879 2.121s-1.32.879-2.121.879zm-6-5h-.003a.988.988 0 0 1-.704-.293l-5-5a.999.999 0 1 1 1.414-1.414L11 13.086V3.5a1 1 0 0 1 2 0v9.586l3.293-3.293a.999.999 0 1 1 1.414 1.414l-5 5a.988.988 0 0 1-.704.293H12z" /></svg>Download Brochure</a>
                                                        </div>
                                                        : ''
                                                }
                                                {/* {
                                                    index == 0 ? <a className="video-btn" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalHomeWatchVideo"><span className="icon-bg"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="6 9.5 24 24"><path fill="currentColor" d="M26.461 21.5a1.515 1.515 0 0 1-.73 1.299l-13.853 8.475a1.533 1.533 0 0 1-2.339-1.3V13.027a1.527 1.527 0 0 1 .781-1.33 1.53 1.53 0 0 1 1.558.03l13.853 8.474a1.515 1.515 0 0 1 .73 1.299z" /></svg></span><span className="name">Watch Video</span></a> : ''
                                                } */}
                                            </div>
                                            {
                                                index == 0 ?
                                                    <div className="cap-right">
                                                        <div className="cap-right-inn">
                                                        
                                                            {
                                                                curEle.vParagraph ? <div className="right-desc">{curEle.vParagraph}</div> : ''
                                                            }
                                                            <ul className="desc-list">
                                                                <li><span className="name">Automobile Parts</span></li>
                                                                <li><span className="name">Machined Components</span></li>
                                                                <li><span className="name">Brass Fittings</span></li>
                                                                <li><span className="name">Stainless Steel Fittings</span></li>
                                                                <li><span className="name">Electrical Components</span></li>
                                                                <li><span className="name">Aluminum Lugs</span></li>
                                                            </ul>
                                                            <div className="slider-right-btn">
                                                                <a className="btn-outline s-download-brochure btn-icon btn-white hvr-sweep-to-top" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalDownloadBrochure"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 21.5H6a2.982 2.982 0 0 1-2.121-.879A2.978 2.978 0 0 1 3 18.5v-2a1 1 0 1 1 2 0v2c0 .264.107.521.293.707.186.186.444.293.707.293h12a1.002 1.002 0 0 0 1-1v-2a1 1 0 1 1 2 0v2c0 .801-.313 1.555-.879 2.121s-1.32.879-2.121.879zm-6-5h-.003a.988.988 0 0 1-.704-.293l-5-5a.999.999 0 1 1 1.414-1.414L11 13.086V3.5a1 1 0 0 1 2 0v9.586l3.293-3.293a.999.999 0 1 1 1.414 1.414l-5 5a.988.988 0 0 1-.704.293H12z" /></svg>Download Brochure</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
            <div className="clear"></div>
            <div className="content-main">
                <div className="section-white home-product-section pdb-0">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">{data?.section2?.Heading}</h3>
                            <div className="desc">{data?.section2?.Paragraph}</div>
                        </div>
                        <div className="HomeProductSlider">
                            <ProductSliderCommon/>
                        </div>
                        <div className="view-all-product">
                            <Link className="btn-outline hvr-sweep-to-top" to="/products">View All</Link>
                        </div>
                    </div>
                </div>
                <div className="section-white home-about-section">
                    <div className="container container-big">
                        <div className="home-about-inn">
                            <div className="left-section">
                                <div className="left-section-inn MainDiv TabHeading">
                                    <div className="section-tit">
                                        <h3 className="tit"><span className="sub-tit">{data?.section3?.Heading}</span>{data?.section3?.SubHeading}</h3>
                                        <div className="desc">{data?.section3?.Paragraph}</div>
                                    </div>
                                    <div className="show-hide-content">
                                        <ul className="about-list">
                                            {
                                                data?.section3?.ListItemArr?.map((curEle, index) => {
                                                    return <li key={index}>{curEle}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="more-btn"><a className="btn-outline btn-icon-right hvr-sweep-to-top more TabHeadingMore" href="javascript:;">{data?.section3?.ListButtonLabel}<svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="-7 -6 24 24"><path fill="currentColor" d="M5 16c-.426 0-.853-.163-1.178-.488l-6.667-6.667A1.665 1.665 0 1 1-.488 6.489L5 11.977l5.488-5.488a1.665 1.665 0 1 1 2.356 2.356l-6.666 6.667A1.662 1.662 0 0 1 5 16zM5 6c-.426 0-.853-.163-1.178-.488l-6.667-6.667A1.666 1.666 0 1 1-.488-3.512L5 1.977l5.488-5.488a1.665 1.665 0 1 1 2.356 2.357L6.179 5.512A1.666 1.666 0 0 1 5 6z" /></svg></a><a className="btn-outline btn-icon-right hvr-sweep-to-top less TabHeadingMore" href="javascript:;">Less<svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="-7 -6 24 24"><path fill="currentColor" d="M5-4c.426 0 .853.163 1.178.488l6.667 6.667a1.665 1.665 0 1 1-2.356 2.356L5 .023-.488 5.511a1.665 1.665 0 1 1-2.356-2.356l6.666-6.667A1.662 1.662 0 0 1 5-4zM5 6c.426 0 .853.163 1.178.488l6.667 6.667a1.665 1.665 0 1 1-2.356 2.356L5 10.023l-5.488 5.488a1.665 1.665 0 1 1-2.356-2.356l6.666-6.667A1.662 1.662 0 0 1 5 6z" /></svg></a></div>
                                </div>
                            </div>
                            <div className="right-section">
                                <img loading="lazy" className="about-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                                <div className="about-tit">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo.png`} alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                                    Jamnagar, Gujarat, India.
                                </div>
                                <img loading="lazy" className="slider-img" src={data?.section3?.imageList[1]['vImage']} alt={data?.section3?.imageList[1]['vSection3ImageAltText']} title={data?.section3?.imageList[1]['vSection3ImageTitleText']} />
                                {/* <div className="HomeAboutSlider">
                                    <Slider {...HomeAboutSlider}>
                                        {
                                            Object.keys(data?.section3?.imageList).map((curEle, index) => {
                                                const subData=data?.section3?.imageList[curEle];
                                                return <div className="item" key={index}>
                                                    <div className="slider-image">
                                                        <img className="slider-img" src={subData?.vImage} alt={subData?.vSection3ImageAltText} title={subData?.vSection3ImageTitleText} />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </Slider>
                                </div> */}
                            </div>
                        </div>
                        <div className="visit-about">
                            <Link className="btn" to={data?.section3?.ButtonLink}>{data?.section3?.ButtonLabel}</Link>
                        </div>
                    </div>
                </div>
                <div className="section-white home-list-section">
                    <div className="container container-big2">
                        <ul className="list-section">
                            <li className="item">
                                <div className="bg-inn">
                                    <div className="tit-section">
                                        <h3 className="tit">{data?.section4?.Heading1?.split(" ")[0]}<br /> {data?.section4?.Heading1?.split(" ").length > 1 ? data?.section4?.Heading1?.split(" ")[1] : ""}</h3>
                                        <div className="icon"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/icon/customer-satisfaction.svg`} alt="Customer Satisfaction" title="Customer Satisfaction" /></div>
                                    </div>
                                    <div className="content">
                                        <ul className="list-bullet">
                                            {
                                                data?.section4?.Block1ListArr.map((curEle, index) => {
                                                    return <li key={index}>{curEle}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="item">
                                <div className="bg-inn">
                                    <div className="tit-section">
                                        <h3 className="tit">{data?.section4?.Heading2?.split(" ")[0]}<br /> {data?.section4?.Heading2?.split(" ").length > 1 ? data?.section4?.Heading2?.split(" ")[1] : ""}</h3>
                                        <div className="icon"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/icon/operational-excellence.svg`} alt="Operational Excellence" title="Operational Excellence" /></div>
                                    </div>
                                    <div className="content">
                                        <ul className="list-bullet">
                                            {
                                                data?.section4?.Block2ListArr.map((curEle, index) => {
                                                    return <li key={index}>{curEle}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className="item">
                                <div className="bg-inn">
                                    <div className="tit-section">
                                        <h3 className="tit">{data?.section4?.Heading3?.split(" ")[0]}<br /> {data?.section4?.Heading3?.split(" ").length > 1 ? data?.section4?.Heading3?.split(" ")[1] : ""}</h3>
                                        <div className="icon"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/icon/competitiveness.svg`} alt="Competitiveness" title="Competitiveness" /></div>
                                    </div>
                                    <div className="content">
                                        <ul className="list-bullet">
                                            {
                                                data?.section4?.Block3ListArr.map((curEle, index) => {
                                                    return <li key={index}>{curEle}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="section-white home-statistics-section2">
                    <div className="container container-big">
                        <ul className="statistics-list">
                            <div className="statistics-logo"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                            <img loading="lazy" className="statistics-line" src={`${process.env.PUBLIC_URL}/images/statistics-line2.png`} alt="Shiv Om Brass Jamnagar, Gujarat, India." title="Shiv Om Brass Jamnagar, Gujarat, India." />
                            <li className="plants">
                                {
                                    data?.section5?.Data1 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data1}</span>{data?.section5?.DataSign1} {data?.section5?.DataExtra1 != "" ? <span className="small-text">{data?.section5?.DataExtra1}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile1 != "" ? <h4 className="tit">{data?.section5?.Titile1}</h4> : ""
                                }
                            </li>
                            <li className="exports">
                                {
                                    data?.section5?.Data2 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data2}</span>{data?.section5?.DataSign2} {data?.section5?.DataExtra2 != "" ? <span className="small-text">{data?.section5?.DataExtra2}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile2 != "" ? <h4 className="tit">{data?.section5?.Titile2}</h4> : ""
                                }
                            </li>
                            <li className="industries-cater">
                                {
                                    data?.section5?.Data4 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data4}</span>{data?.section5?.DataSign4} {data?.section5?.DataExtra4 != "" ? <span className="small-text">{data?.section5?.DataExtra4}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile4 != "" ? <h4 className="tit">{data?.section5?.Titile4}</h4> : ""
                                }
                            </li>
                            <li className="infrastructure">
                                {
                                    data?.section5?.Data3 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data3}</span>{data?.section5?.DataSign3} {data?.section5?.DataExtra3 != "" ? <span className="small-text">{data?.section5?.DataExtra3}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile3 != "" ? <h4 className="tit">{data?.section5?.Titile3}</h4> : ""
                                }
                            </li>
                            <li className="employees">
                                {
                                    data?.section5?.Data5 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data5}</span>{data?.section5?.DataSign5} {data?.section5?.DataExtra5 != "" ? <span className="small-text">{data?.section5?.DataExtra5}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile5 != "" ? <h4 className="tit">{data?.section5?.Titile5}</h4> : ""
                                }
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="section-white home-statistics-section">
                    <div className="container container-big">
                        <ul className="statistics-list">
                            <div className="statistics-logo"><img src={`${process.env.PUBLIC_URL}/images/shivom-brass-stastics-logo.png`} alt="Shiv Om Brass Industries" title="Shiv Om Brass Industries" /></div>
                            <img className="statistics-line" src={`${process.env.PUBLIC_URL}/images/statistics-line.png`} alt="Shiv Om Brass Jamnagar, Gujarat, India." title="Shiv Om Brass Jamnagar, Gujarat, India." />
                            <li className="plants">
                                {
                                    data?.section5?.Data1 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data1}</span>{data?.section5?.DataSign1} {data?.section5?.DataExtra1 != "" ? <span className="small-text">{data?.section5?.DataExtra1}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile1 != "" ? <h4 className="tit">{data?.section5?.Titile1}</h4> : ""
                                }
                            </li>
                            <li className="exports">
                                {
                                    data?.section5?.Data2 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data2}</span>{data?.section5?.DataSign2} {data?.section5?.DataExtra2 != "" ? <span className="small-text">{data?.section5?.DataExtra2}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile2 != "" ? <h4 className="tit">{data?.section5?.Titile2}</h4> : ""
                                }
                            </li>
                            <li className="infrastructure">
                                {
                                    data?.section5?.Data3 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data3}</span>{data?.section5?.DataSign3} {data?.section5?.DataExtra3 != "" ? <span className="small-text">{data?.section5?.DataExtra3}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile3 != "" ? <h4 className="tit">{data?.section5?.Titile3}</h4> : ""
                                }
                            </li>
                            <li className="industries-cater">
                                {
                                    data?.section5?.Data4 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data4}</span>{data?.section5?.DataSign4} {data?.section5?.DataExtra4 != "" ? <span className="small-text">{data?.section5?.DataExtra4}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile4 != "" ? <h4 className="tit">{data?.section5?.Titile4}</h4> : ""
                                }
                            </li>
                            <li className="employees">
                                {
                                    data?.section5?.Data5 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data5}</span>{data?.section5?.DataSign5} {data?.section5?.DataExtra5 != "" ? <span className="small-text">{data?.section5?.DataExtra5}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile5 != "" ? <h4 className="tit">{data?.section5?.Titile5}</h4> : ""
                                }
                            </li>
                            <li className="products">
                                {
                                    data?.section5?.Data6 != "" ? <h3 className="counter-main"><span className="counter">{data?.section5?.Data6}</span>{data?.section5?.DataSign6} {data?.section5?.DataExtra6 != "" ? <span className="small-text">{data?.section5?.DataExtra6}</span> : ""}</h3> : ""
                                }
                                {
                                    data?.section5?.Titile6 != "" ? <h4 className="tit">{data?.section5?.Titile6}</h4> : ""
                                }
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* <div className="section-white home-awards-section pdt-0">
                    <div className="container container-big">
                        <div className="tit-section">
                            <h3 className="tit">{data?.section6?.Heading}</h3>
                            <div className="desc">{data?.section6?.SubHeading}</div>
                        </div>
                    </div>
                    <div className="HomeAwardsSlider">
                            {
                                Object.keys(data?.section6?.imageList)?.map((curEle, index) => {
                                    const subData=data?.section6?.imageList[curEle];
                                    return <div className="item" key={index}>
                                        <div className="awards-logo"><img loading="lazy" src={subData['vImage']} alt={subData['sect6imageAltText']} title={subData['sect6imageTitleText']} /></div>
                                    </div>
                                })
                            }
                    </div>
                </div> */}
                {/* <div className="section-white home-testimonial-section">
                    <div className="container container-big">
                        <div className="testimonial-inn">
                            <div className="left-part">
                                <h4 className="tit"><span className="sub-tit">{data?.section7?.Heading}</span>
                                <span dangerouslySetInnerHTML={{__html:data?.section7?.SubHeading}}></span>
                                </h4>
                            </div>
                            <div className="right-part">
                                <div className="HomeTestimonialSlider">
                                    <Slider {...HomeTestimonialSlider}>
                                        {
                                            testimonialdata.map((curEle, index) => {
                                                return <div className="item" key={index}>
                                                    <div className="testimonial-item">
                                                        <div className="testimonial-desc">{curEle?.vTestimonial}</div>
                                                        <div className="testimonial-bottom">
                                                            <div className="testimonial-user">
                                                                {
                                                                    curEle?.vImage != "" && curEle?.vImage != null ?
                                                                        <div className="user">
                                                                            <img src={curEle?.vImage} alt={curEle?.vImageAltText} title={curEle?.vImageTitleText} />
                                                                        </div>
                                                                        : ""
                                                                }
                                                                <div className="testimonial-name">
                                                                    {
                                                                        curEle?.vClientName != "" ?
                                                                            <div className="name">{curEle?.vClientName}</div>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        curEle?.vDesignation != "" ?
                                                                            <div className="designation">{curEle?.vDesignation}</div>
                                                                            : ""
                                                                    }
                                                                    {
                                                                        curEle?.vCompany != "" ?
                                                                            <div className="company-name">{curEle?.vCompany}</div>
                                                                            : ""
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="testimonial-logo"><img src={curEle?.VLogo} alt={curEle?.vLogoAltText} title={curEle?.vLogoTitleText} /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="section-white home-industries-section">
                    <div className="container container-big">
                        <div className="section-title">
                            <h3 className="title">{data?.section8?.Heading}</h3>
                            <div className="desc">{data?.section8?.SubHeading}</div>
                        </div>
                        <div className="HomeIndustriesSlider">
                            <HomeIndustriesComp/>
                        </div>
                    </div>
                </div>
                {/* <div className="section-white home-director-section">
                    <div className="container">
                        <div className="list-section">
                            <div className="list director-title">
                                <h2 className="name-tit">{data?.section9?.DirectorName}<span className="name-sub">{data?.section9?.DirectorLabel}</span></h2>
                            </div>
                            <div className="list director-image">
                                <img loading="lazy" className="image" src={`${process.env.PUBLIC_URL}/images/shivom-brass-sales.png`} alt="Yash Gohel Director Shiv Om Brass Industries" title="Yash Gohel" />
                            </div>
                            <div className="list director-contact">
                                <h2 className="tit">{data?.section9?.ContactLabel}</h2>
                                <h3 className="tit2">{data?.section9?.ContactText}</h3>
                                {
                                    data?.section9?.ButtonLabel != "" || data?.section9?.BrochureLabel != "" ?
                                        <ul className="btn-list">
                                            {
                                                data?.section9?.ButtonLabel != "" ?
                                                    <li>
                                                        <Link className="btn btn-icon-right" to={data?.section9?.ButtonLink}>
                                                            <svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="20" height="20" viewBox="0 -2 20 20"><path fill="currentColor" d="M12 16a.999.999 0 0 1-.707-1.707L16.586 9H1a1 1 0 1 1 0-2h15.586l-5.293-5.293A.999.999 0 1 1 12.707.293l7 7a.993.993 0 0 1 .293.704v.006a.988.988 0 0 1-.293.704l-7 7A.997.997 0 0 1 12 16z" /></svg>{data?.section9?.ButtonLabel}
                                                        </Link>
                                                    </li>
                                                    : ""
                                            }
                                            {
                                                data?.section9?.BrochureLabel != "" ?
                                                    <li><a className="btn-line btn-icon" href="javascript:;" data-bs-toggle="modal" data-bs-target="#ModalDownloadBrochure"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M18 21.5H6a2.982 2.982 0 0 1-2.121-.879A2.978 2.978 0 0 1 3 18.5v-2a1 1 0 1 1 2 0v2c0 .264.107.521.293.707.186.186.444.293.707.293h12a1.002 1.002 0 0 0 1-1v-2a1 1 0 1 1 2 0v2c0 .801-.313 1.555-.879 2.121s-1.32.879-2.121.879zm-6-5h-.003a.988.988 0 0 1-.704-.293l-5-5a.999.999 0 1 1 1.414-1.414L11 13.086V3.5a1 1 0 0 1 2 0v9.586l3.293-3.293a.999.999 0 1 1 1.414 1.414l-5 5a.988.988 0 0 1-.704.293H12z"></path></svg>{data?.section9?.BrochureLabel}</a></li>
                                                    : ""
                                            }
                                        </ul>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="section-white home-bottom-section home-certificate3-section pdb-0">
                    <div className="container container-big">
                        <HomeDunsCommon dunAccess={dunAccess}/>
                    </div>
                </div>
                <div className="section-white home-news-section">
                    <div className="container container-big">
                        <div className="section-title">
                            {
                                data?.section11?.Heading != "" ?
                                    <h3 className="title">{data?.section11?.Heading}</h3>
                                    : ""
                            }
                            {
                                data?.section11?.SubHeading != "" ?
                                    <div className="desc">{data?.section11?.SubHeading}</div>
                                    : ""
                            }
                        </div>
                        <ul className="news-list-sec">
                            <li className="left-li">
                                <div className="post-list">
                                    {
                                        result2?.length>0?
                                        <>
                                        <div className="post-image"><a href="javascript:;" onClick={()=>blogDetailPage(result2[0]['vTitle'])} ><img loading="lazy" src={result2[0]['vBlogImage']} title={result2[0]['vImageTitleText']} alt={result2[0]['vImageAltText']} /></a></div>
                                        <div className="post-cap">
                                            <h3 className="post-title"><a href="javascript:;" onClick={()=>blogDetailPage(result2[0]['vTitle'])}>{result2[0]['vTitle']}</a></h3>
                                            <div className="post-desc">{result2[0]['vBlogDesc']?.substr(0,118) + '...'}</div>
                                            <div className="post-cat">In{" "}
                                                {
                                                    result2[0]['vCategory'].map((curEle,index)=>{
                                                        return <a href="javascript:;" key={index}>{curEle}</a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        </>
                                        :''
                                    }
                                </div>
                            </li>
                            <li className="mid-li">
                                <div className="post-list">
                                    {
                                        result2?.length>1?
                                        <>
                                        <div className="post-image"><a href="javascript:;" onClick={()=>blogDetailPage(result2[1]['vTitle'])}><img loading="lazy" src={result2[1]['vBlogImage']} title={result2[1]['vImageTitleText']} alt={result2[1]['vImageAltText']} /></a></div>
                                        <div className="post-cap">
                                            <h3 className="post-title"><a href="javascript:;" onClick={()=>blogDetailPage(result2[1]['vTitle'])} >{result2[1]['vTitle']}</a></h3>
                                            <div className="post-desc">{result2[1]['vBlogDesc']?.substr(0,118) + '...'}</div>
                                            <div className="post-cat">In{" "} 
                                            {
                                                    result2[1]['vCategory'].map((curEle,index)=>{
                                                        return <a href="javascript:;" key={index}>{curEle}</a>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        </>
                                        :''
                                    }
                                </div>
                            </li>
                            <li className="rigth-li">
                                {
                                    result.reverse().map((curEle,index)=>{
                                        return <div className="post-list" key={index}>
                                                    <div className="post-image"><a href="javascript:;" onClick={()=>blogDetailPage(curEle?.vTitle)}><img loading="lazy" src={curEle.vBlogImage} title={curEle.vImageTitleText} alt={curEle.vImageAltText} /></a></div>
                                                    <div className="post-cap">
                                                        <h3 className="post-title"><a href="javascript:;" onClick={()=>blogDetailPage(curEle?.vTitle)}>{curEle?.vTitle}</a></h3>
                                                        <div className="post-desc">{curEle?.vTitle}</div>
                                                        <div className="post-cat">In{" "}
                                                            {
                                                                curEle?.vCategory?.map((curTag,ind)=>{
                                                                    return <a href="javascript:;" key={ind}>{curTag}</a>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                    })
                                }
                                <div className="view-all-news">
                                    <Link to="/news-events" className="btn-outline hvr-sweep-to-top">
                                        View All
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <DownloadBrochureModal/>
            <AppendScript />
        </CommonLayout>
    )
}

export default Home

const ProductNextArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className="slide-arrow next-arrow slick-arrow"><svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="24" height="24" viewBox="-5.5 -1 24 24"><path fill="currentColor" d="M2.214 1c.366 0 .731.14 1.011.419l8.571 8.571a1.43 1.43 0 0 1 0 2.021l-8.571 8.571a1.43 1.43 0 0 1-2.021-2.021L8.766 11 1.204 3.438A1.428 1.428 0 0 1 2.214 1z" /></svg></button>
    );
}

const ProductPrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button onClick={onClick} className="slide-arrow prev-arrow slick-arrow"><svg className="icon" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="24" height="24" viewBox="-5.5 -1 24 24"><path fill="currentColor" d="M10.786 21c-.366 0-.731-.14-1.011-.419L1.204 12.01a1.428 1.428 0 0 1 0-2.02l8.571-8.571a1.43 1.43 0 0 1 2.021 2.02L4.234 11l7.562 7.562A1.428 1.428 0 0 1 10.786 21z" /></svg></button>
    );
}