import React, { useEffect } from 'react'
import CommonLayout from './Common/CommonLayout'
import { scrollBottomToTop } from './Common/Functions';
import prodData from './json/product.json';
import { UploadUrl } from '../Services/CommonUrl';
import productCategory from './json/product_category.json'
import general from './json/general.json';
import { useLocation, useNavigate } from 'react-router-dom';

function SubProduct() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        scrollBottomToTop();
    },[])

    const returnCategoryName = (iCategoryId) => {
        const getRecord = productCategory.filter((curEle, index) => {
            return curEle.iCategoryId == iCategoryId
        })
        return getRecord.length > 0 ? getRecord[0]['iCategoryName'] : ""
    }

    const filterData=prodData.filter((curEle,index)=>{
        const categoryName=returnCategoryName(curEle.iCategoryId)
        const urlName=curEle.vProductName+'-'+categoryName;
        const b = urlName.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        return "/sub-product/"+b==location.pathname
    })

    const productDetailPage = (name,isSubProduct=0) =>{
        const b = name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        if(isSubProduct==0){
            navigate(`/product/${b}`);
        }else{
            navigate(`/sub-product/${b}`);
        }
    };

    useEffect(()=>{
        if(filterData.length==0){
            navigate(`/products`);
        }
    },[])

  return (
    <CommonLayout>
            <div className="content-main sub-page">
                <div className="section-white comman-header-section pdb-0">
                    <img loading="lazy" className="header-logo-bg" src={`${process.env.PUBLIC_URL}/images/shivom-brass-about-logo-bg.png`}
                        alt="Shiv Om Brass Industries Jamnagar, Gujarat, India." title="Shiv Om Brass Industries" />
                    <div className="container container-big">
                        <div className="section-title2">
                            <h2 className="title"><span className="sub-title">{general?.aProduct?.vProductPageHeading}</span>{general?.aProduct?.vProductPageSubHeading}</h2>
                            <div className="desc">{general?.aProduct?.vProductPageParagraph}</div>
                        </div>
                    </div>
                </div>
                <div className="section-white home-product-section">
                    <div className="container container-big">
                        <div className="product-list-sec">
                            {
                                prodData.map((CurEle,index)=>{
                                    const filterSubProduct=prodData.filter((curData,index)=>{
                                        return curData.iParentProdId==CurEle.iProductId
                                    })
                                    return filterData.length>0 && CurEle.iParentProdId==filterData[0]['iProductId']?<div className="product-item" key={index}>
                                                <div className="product-inn">
                                                    <a href='javascript:void(0)' onClick={()=>productDetailPage(CurEle.vProductName+'-'+returnCategoryName(CurEle.iCategoryId),filterSubProduct.length)} className="product-link">
                                                        <div className="product-img">
                                                            <img loading="lazy" src={UploadUrl + 'product/' +CurEle.vProductImage} alt={CurEle?.vImageAltText} title={CurEle?.vImageTitleText} />
                                                        </div>
                                                        <h4 className="product-name">
                                                            <span>{CurEle.vProductName} <br />{returnCategoryName(CurEle.iCategoryId)}</span>
                                                        </h4>
                                                    </a>
                                                </div>
                                            </div>:''
                                })
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </CommonLayout>
  )
}

export default SubProduct