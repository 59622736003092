import React from 'react'
import data from '../json/home.json';
import { Link } from 'react-router-dom';

function DunsCommon(props) {
    const submitForm=()=>{
        var form2 = document.getElementById('form1');
        form2.action = 'https://profiles.dunsregistered.com/TPIN-BAS-004.aspx';
        window.open('', 'formresult','toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=0,width=1020,height=700,Left=0,top=0,titlebar=yes');
        form2.submit();
    }

    return (
        <ul className="bottom-list">
            <li className="join-list">
                <div className="in-bg">
                    {
                        data?.section10?.Box1Heading != "" ?
                            <h4 className="join-tit">{data?.section10?.Box1Heading}</h4>
                            : ""
                    }
                    {
                        data?.section10?.Box1Paragraph != "" ?
                            <div className="join-desc">{data?.section10?.Box1Paragraph}</div>
                            : ""
                    }
                    {
                        data?.section10?.Box1ButtonLabel != "" ?
                            <div>
                                <Link className="btn btn-icon-right" to={data?.section10?.Box1ButtonLink}>
                                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="20" height="20" viewBox="0 -2 20 20"><path fill="currentColor" d="M12 16a.999.999 0 0 1-.707-1.707L16.586 9H1a1 1 0 1 1 0-2h15.586l-5.293-5.293A.999.999 0 1 1 12.707.293l7 7a.993.993 0 0 1 .293.704v.006a.988.988 0 0 1-.293.704l-7 7A.997.997 0 0 1 12 16z"></path></svg>{data?.section10?.Box1ButtonLabel}
                                </Link>
                            </div>
                            : ""
                    }
                </div>
            </li>
            {/* <li className="training-list">
                <div className="in-bg">
                    <img className="training-icon" src={`${process.env.PUBLIC_URL}/images/icon/training-programs-icon.png`} alt="Training Programs" title="Training Programs" />
                    {
                        data?.section10?.Box2Heading != "" ?
                            <h4 className="training-tit" dangerouslySetInnerHTML={{ __html: data?.section10?.Box2Heading }}></h4>
                            : ""
                    }
                    <div className="training-desc">
                        {
                            data?.section10?.Box2Paragraph != "" ?
                                <div className="desc">{data?.section10?.Box2Paragraph}</div>
                                : ""
                        }
                        {
                            data?.section10?.Box2ButtonLabel != "" ?
                                <div className="training-btn">
                                    <Link className="btn btn-white btn-icon-right" to={data?.section10?.Box2ButtonLink}>
                                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="20" height="20" viewBox="0 -2 20 20"><path fill="currentColor" d="M12 16a.999.999 0 0 1-.707-1.707L16.586 9H1a1 1 0 1 1 0-2h15.586l-5.293-5.293A.999.999 0 1 1 12.707.293l7 7a.993.993 0 0 1 .293.704v.006a.988.988 0 0 1-.293.704l-7 7A.997.997 0 0 1 12 16z"></path></svg>{data?.section10?.Box2ButtonLabel}
                                    </Link>
                                </div> : ""
                        }
                    </div>
                </div>
            </li> */}
            <li className="certificate-list">
                <div className="in-bg">
                    <div className="top-certificate"><img loading="lazy" src={`${process.env.PUBLIC_URL}/images/dandb-duns-registered.png`} alt="D&B D-U-N-S Registered" title="D&B D-U-N-S Registered" /></div>
                    <div className="certificate-bg">
                        <h4 className="certificate-tit" dangerouslySetInnerHTML={{ __html: data?.section10?.Box3Heading }}></h4>
                        <div className="certificate-view-btn">
                            {/* <Link className="btn btn-icon-right" to={data?.section10?.Box3ButtonLink}>
                                                <svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="20" height="20" viewBox="0 -2 20 20"><path fill="currentColor" d="M12 16a.999.999 0 0 1-.707-1.707L16.586 9H1a1 1 0 1 1 0-2h15.586l-5.293-5.293A.999.999 0 1 1 12.707.293l7 7a.993.993 0 0 1 .293.704v.006a.988.988 0 0 1-.293.704l-7 7A.997.997 0 0 1 12 16z"></path></svg>{data?.section10?.Box3ButtonLabel}
                                            </Link> */}
                            <form id="form1" method="post" action="" target="formresult">
                                <input className="api_key_cart" name="iSealKey" type="text" id="iSealKey" style={{ display: 'none' }} value={props.dunAccess} />
                                <a id="ATpSeal" onClick={submitForm} className="btn btn-icon-right" target="_parent"><svg className="icon" xmlns="http://www.w3.org/2000/svg" space="preserve" width="20" height="20" viewBox="0 -2 20 20"><path fill="currentColor" d="M12 16a.999.999 0 0 1-.707-1.707L16.586 9H1a1 1 0 1 1 0-2h15.586l-5.293-5.293A.999.999 0 1 1 12.707.293l7 7a.993.993 0 0 1 .293.704v.006a.988.988 0 0 1-.293.704l-7 7A.997.997 0 0 1 12 16z"></path></svg>View</a>
                            </form>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    )
}

export default DunsCommon